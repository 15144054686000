class CaFormButtonSubmit extends HTMLElement {
    constructor() {
        super();

        this.config        = {};
        this.host          = this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host;
        this.subscriptions = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }
            :host {
                font-family: var(--ca-font-family);
                display:     flex;
                flex:        1;
            }
            .ca-form-button-submit {
                display: flex;
                flex:    1;
            }
            .ca-form-button-submit a {
                display:          flex;
                flex:             1;
                flex-direction:   column;
                align-items:      center;
                justify-content:  center;
                min-height:       40px;
                font-size:        var(--ca-font-size--medium);
                font-weight:      500;
                text-decoration:  none;
                color:            var(--ca-ui-form-button-submit--fg);
                background-color: var(--ca-ui-form-button-submit--bg);
                border-width:     2px;
                border-color:     var(--ca-ui-form-button-submit--border-color);
                border-style:     solid;
                border-radius:    5px;
                box-shadow:       rgba(0,0,0,0.15) 0 0 10px;
                transition:       box-shadow 0.2s;
            }
            .ca-form-button-submit a:hover {
                color:            var(--ca-ui-form-button-submit--fg-hover);
                background-color: var(--ca-ui-form-button-submit--bg-hover);
                border-color:     var(--ca-ui-form-button-submit--border-color-hover);
                box-shadow:       rgba(0,0,0,0.4) 0 0 12px;
            }
            .ca-form-button-submit a:focus {
                color:            var(--ca-ui-form-button-submit--fg-focus);
                background-color: var(--ca-ui-form-button-submit--bg-focus);
                border-color:     var(--ca-ui-form-button-submit--border-color-focus);
                box-shadow:       rgba(0,0,0,0.5) 0 0 12px;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div
                class = "ca-form-button-submit"
            >
                <a
                    href     = "#"
                    tabindex = "2"
                >
                    <span></span>
                </a>
            </div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const fieldInput = this.shadowRoot.querySelector('a');
        this.eventListenerFieldInputClick = (e) => this.receiveUserInput(e);
        fieldInput.addEventListener(
            'click',
            this.eventListenerFieldInputClick
        );
        this.eventListenerFieldInputKeydown = (e) => this.onKeyDown(e);
        fieldInput.addEventListener(
            'keydown',
            this.eventListenerFieldInputKeydown
        );
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:attached:response',
                (params) => this.receiveCaFormLayoutChildAttachedResponse(params)
            )
        );
        this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:attached:request',
            {
                elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                elColIdx: parseInt(this.getAttribute('col-idx'), 10)
            }
        );
    }

    disconnectedCallback() {
        const fieldInput = this.shadowRoot.querySelector('a');
        fieldInput.removeEventListener(
            'click',
            this.eventListenerFieldInputClick
        );
        fieldInput.removeEventListener(
            'keydown',
            this.eventListenerFieldInputKeydown
        );
    }

    // ---------------------------------------------------------------------

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.receiveUserInput(e);
        }
    }

    receiveCaFormLayoutChildAttachedResponse({
        rowIdx = 0,
        colIdx = 0,
        config = {}
    }) {
        if (
            rowIdx === parseInt(this.getAttribute('row-idx'), 10) &&
            colIdx === parseInt(this.getAttribute('col-idx'), 10)
        ) {
            this.config.fieldId    = config?.fieldId || '';
            this.config.align      = config?.align   || 'stretch';
            this.config.title      = config?.title   || '';
            this.config.class      = config?.class   || '';

            if (config.style) {
                this.shadowRoot.firstChild.innerHTML += config.style;
            }

            const buttonTitleElem = this.shadowRoot.querySelector('span');
            buttonTitleElem.innerHTML = this.config.title;

            const buttonElem = this.shadowRoot.querySelector('.ca-form-button-submit');

            if (this.config.class !== '') {
                buttonElem.classList.add(this.config.class);
            }
            if (config.focus) {
                buttonElem.querySelector('a').focus();
            }

            this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host.caFormLayoutEventAggregator.publish(
                'CaFormLayoutChild:rendered:request',
                {
                    elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                    elColIdx: parseInt(this.getAttribute('col-idx'), 10)
                }
            );
        }
    }

    // ---------------------------------------------------------------------

    receiveUserInput(e) {
        if (this.config.fieldId) {
            this.host.caFormLayoutEventAggregator.publish(
                'CaFormLayoutChild:userInput:request',
                {
                    rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                    colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                    fieldId:    this.config.fieldId,
                    fieldValue: '1'
                }
            );
        }

        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayout:submit:request'
        );

        e.preventDefault();
    }
}

customElements.define('ca-form-button-submit', CaFormButtonSubmit);

export {CaFormButtonSubmit};

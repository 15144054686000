import {CaAppConfig} from '../ca-app/ca-app-config.js';

/**
 * Provides methods related to REST requests and responses.
 */
const CaToolRequest = {

    queue:                   [],
    queueHandlingInProgress: false,

    decodeHTML(value) {
        if (typeof value === 'string') {
            const replaceValues = {
                    '&Auml;':  'Ä',
                    '&auml;':  'ä',
                    '&Ouml;':  'Ö',
                    '&ouml;':  'ö',
                    '&Uuml;':  'Ü',
                    '&uuml;':  'ü',
                    '&szlig;': 'ß'
                };

            for (let replaceValue in replaceValues) {
                value = value.replace(replaceValue, replaceValues[replaceValue]);
            }
        }

        return value;
    },

    async WS_Start_Service(parameters) {
        return new Promise((resolve, reject) => {
            this.queue.push({
                parameters: parameters,
                resolve:    resolve,
                reject:     reject
            });
            this.handleQueue();
        });
    },

    async handleQueue() {
        if (this.queueHandlingInProgress ||
            this.queue.length === 0) {

            return false;
        }

        this.queueHandlingInProgress = true;

        let params = this.queue.shift();

        let response = await this.WS_Start_Service_PHP(
                params.parameters
            );
        params.resolve(response);
        this.queueHandlingInProgress = false;
        this.handleQueue();
    },

    async WS_Start_Service_PHP(parameters) {
        let remoteServerAddress = CaAppConfig.connection.remoteServerAddress,
            result = {
                error:      0,
                errorCode:  '',
                errorText:  '',
                response:   ''
            },
            myHeaders = new Headers(),
            myRequest,
            phpResponse,
            phpResult;

        if (remoteServerAddress.substr(-1) === '/') {
            remoteServerAddress = remoteServerAddress.substr(0, remoteServerAddress.length -1);
        }
        if (remoteServerAddress.substr(0, 4) !== 'http' &&
            remoteServerAddress.substr(0, 5) !== 'https') {
            remoteServerAddress = 'http://' + remoteServerAddress;
        }

        parameters.WebserviceUrl = remoteServerAddress;

        myHeaders.append(
            'Accept',
            'application/json'
        );
        myHeaders.append(
            'Content-Type',
            'application/json'
        );

        myRequest = new Request(
            '/requestLayer.php',
            {
                headers: myHeaders,
                method: 'POST',
                body:    JSON.stringify(parameters)
            }
        );

        try {
            phpResponse = await fetch(myRequest);
        } catch(e) {
            result = {
                error:      1,
                errorCode:  'rezepte: -1',
                errorText:  'Verbindung zum Datenserver nicht möglich',
                response:   ''
            };
        }

        phpResult = await phpResponse.json();

        if (phpResult === '') {
            result.error     = 1;
            result.errorCode = 'rezepte: -2';
            result.errorText = 'Keine Antwort vom Datenserver empfangen.';
            result.response  = '';
        } else {
            result.error     = phpResult.error;
            result.errorCode = this.decodeHTML(phpResult.errorCode);
            result.errorText = this.decodeHTML(phpResult.errorText);
            result.response  = phpResult.response;
        }

        return result;
    }
}

export {CaToolRequest};

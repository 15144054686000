import {CaFormLayoutValidator} from './ca-form-layout-validator';
import {CaToolEventAggregator} from '../../../ca-tools/ca-tool-event-aggregator';
import {CaTools}               from '../../../ca-tools/ca-tools';

/**
 * Nimmt eine Layoutdefinition entgegen und erstellt daraus ein HTML-Formular.
 *
 * Enthält einen Fokus-Trapper, mit dem verhindert wird, dass die Tabtaste den
 * Fokus aus dem Layout versetzt.
 */
class CaFormLayout extends HTMLElement {
    constructor() {
        super();

        this.caFormLayoutEventAggregator         = new CaToolEventAggregator();
        this.caFormLayoutValidator               = new CaFormLayoutValidator();
        this.caUiLoadingIndicatorEventAggregator = new CaToolEventAggregator();
        this.config                              = {};
        this.configCss                           = '';
        this.hasLayoutDefinition                 = false;
        this.isLoading                           = false;
        this.record                              = {};
        this.subscriptions                       = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();

        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:attached:request',
                (params) => this.answerCaFormLayoutChildAttachedRequest(params)
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:userInput:request',
                (params) => this.answerCaFormLayoutChildUserInputRequest(params)
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:validateOnInput:request',
                (params) => this.answerCaFormLayoutChildValidateOnInputRequest(params)
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:submit:request',
                (params) => this.answerCaFormLayoutSubmitRequest(params)
            )
        );
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }

            :host {
                font-family: var(--ca-font-family);
                display:     flex;
                flex:        initial;
                margin:      0 15px;
            }
            .ca-form-layout-form {
                overflow:         auto;
                position:         absolute;
                left:             0;
                top:              0;
                bottom:           0;
                right:            0;
                display:          flex;
                flex-direction:   column;
            }
            .ca-form-layout-form.use-shadow {
                overflow: visible;
            }
            .ca-form-layout-form.left {
                margin-left:  0;
                margin-right: auto;
            }
            .ca-form-layout-form.center {
                margin-left:  auto;
                margin-right: auto;
            }
            .ca-form-layout-form.right {
                margin-left:  auto;
                margin-right: 0;
            }
            
            .ca-form-layout {
                overflow:         auto;
                display:          flex;
                flex:             1;
                flex-direction:   column;
                padding:          8px;
                color:            var(--ca-ui-form-fg);
                background-color: var(--ca-ui-form-bg);
            }
            .ca-form-layout-form.padding-small .ca-form-layout {
                padding: 8px;
            }
            .ca-form-layout-form.padding-medium .ca-form-layout {
                padding: 16px;
            }
            .ca-form-layout-form.padding-large .ca-form-layout {
                padding: 24px;
            }
            .ca-form-layout-form.use-shadow .ca-form-layout {
                flex:       initial;
                box-shadow: rgba(0,0,0,0.2) 0 10px 42px;
            }
            .ca-form-layout-form.border-radius .ca-form-layout {
                border-radius: 4px;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <form
                class    = "ca-form-layout-form"
                tabindex = "2"
            >
                <div
                    class       = "ca-focus-trapstart"
                    tabindex    = "-1"
                    aria-hidden = "true"
                ></div>

                <div class="ca-form-layout" tabindex="2"></div>

                <div
                    class       = "ca-focus-trapend"
                    tabindex    = "-1"
                    aria-hidden = "true"
                ></div>
                <div
                    tabindex    = "2"
                    class       = "ca-focus-handle-trap-end"
                    aria-hidden = "true"
                ></div>
            </form>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const caFormLayoutElem = this.shadowRoot.querySelector('.ca-form-layout');
        caFormLayoutElem.attachShadow({mode: 'open'});

        let style = document.createElement('style');

        style.textContent = `
            .ca-form-layout-row {
                display:        flex;
                flex:           1;
                flex-direction: row;
                margin-top:     0;
                transition:     margin-top 0.5s;
            }
            .ca-form-layout-col {
                display:    flex;
                flex:       1;
                transition: max-height 0.5s;
            }
        `;

        caFormLayoutElem.shadowRoot.appendChild(style);

        const caFormElem = this.shadowRoot.querySelector('.ca-form-layout-form');
        this.eventListenerCaFormElemFocus = () => this.focusHandleStart();
        this.eventListenerCaFormElemSubmit = () => this.answerCaFormLayoutSubmitRequest();
        this.eventListenerCaFocusEndElemFocus = () => this.focusHandleEnd();

        caFormElem.addEventListener(
            'focus',
            this.eventListenerCaFormElemFocus
        );
        caFormElem.addEventListener(
            'submit',
            () => this.answerCaFormLayoutSubmitRequest
        );
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-handle-trap-end');

        caFocusEndElem.addEventListener(
            'focus',
            this.eventListenerCaFocusEndElemFocus
        );
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.parentNode.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:attached:response',
                (params) => this.receiveCaFormLayoutAttachedResponse(params)
            )
        );
        this.subscriptions.push(
            this.parentNode.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:updateRecord:request',
                (params) => this.answerCaFormLayoutUpdateRecordRequest(params)
            )
        );
        this.subscriptions.push(
            this.parentNode.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:setStatusLoading:response',
                () => this.receiveCaFormLayoutSetStatusLoadingResponse()
            )
        );
        this.subscriptions.push(
            this.parentNode.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:setStatusNotLoading:response',
                () => this.receiveCaFormLayoutSetStatusNotLoadingResponse()
            )
        );
        this.parentNode.host.caFormLayoutEventAggregator.publish('CaFormLayout:attached:request');
    }

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }

        const caFormElem = this.shadowRoot.querySelector('.ca-form');

        if (caFormElem) {
            caFormElem.removeEventListener(
                'submit',
                this.eventListenerCaFormElemSubmit
            );
            caFormElem.removeEventListener(
                'focus',
                this.eventListenerCaFormElemFocus
            );
            const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-handle-trap-end');
            caFocusEndElem.removeEventListener(
                'focus',
                this.eventListenerCaFocusEndElemFocus
            );
        }
    }

    // ---------------------------------------------------------------------

    /**
     * Wird von jeder Formularkomponente aufgerufen, um die Konfiguration
     * für die Komponente zu erhalten.
     */
    answerCaFormLayoutChildAttachedRequest({
        elRowIdx = 0,
        elColIdx = 0
    }) {
        let config = undefined;
        let rowIdx = 0;

        for (const row of this.config.layoutDefinition.rows) {
            if (elRowIdx === rowIdx) {
                let colIdx = 0;

                for (const col of row.cols) {
                    if (elColIdx === colIdx) {
                        config = col.config;
                        break;
                    }

                    colIdx++;
                }
            }

            if (config !== undefined) {
                break;
            }

            rowIdx++;
        }

        this.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:attached:response',
            {
                rowIdx: elRowIdx,
                colIdx: elColIdx,
                config: config,
                value:  this.record.hasOwnProperty(config.fieldId) ? this.record[config.fieldId] : ''
            }
        );
    }

    answerCaFormLayoutUpdateRecordRequest({
        record = {}
    }) {
        this.record = record;

        this.caFormLayoutEventAggregator.publish(
            'CaFormLayout:updateRecord:request',
            {
                record: this.record
            }
        )
    }

    /**
     * Wird von Komponenten aufgerufen, um Änderungen am Datensatz mitzuteilen.
     */
    answerCaFormLayoutChildUserInputRequest({
        rowIdx     = 0,
        colIdx     = 0,
        fieldInfo  = {},
        fieldId    = '',
        fieldValue = ''
    }) {
        if (this.record.hasOwnProperty(fieldId)) {
            this.record[fieldId] = fieldValue;

            this.parseLayoutDefinition();
            this.updateLayout();
        }
    }

    /**
     * Wird von Formularkomponenten aufgerufen, um das Formular abzusenden.
     */
    answerCaFormLayoutSubmitRequest() {
        if (this.isLoading === false) {
            this.isLoading = true;

            setTimeout(
                () => {
                    // Validierung durchführen
                    this.caFormLayoutValidator.setRecord({
                        record: CaTools.copyObjectShallow(this.record)
                    });
                    const validationResult = this.caFormLayoutValidator.validate();

                    if (validationResult.allValid) {
                        this.parentNode.host.caFormLayoutEventAggregator.publish(
                            'CaFormLayout:submit:request',
                            {
                                record: CaTools.copyObjectShallow(this.record)
                            }
                        );
                    } else {
                        this.isLoading = false;
                        this.caFormLayoutEventAggregator.publish(
                            'CaFormLayout:validation.result.request',
                            validationResult.colMessages
                        );
                    }
                },
                50
            );
        }
    }

    answerCaFormLayoutChildValidateOnInputRequest({
        rowIdx     = 0,
        colIdx     = 0,
        col        = {},
        type       = 'validateOnInput',
        fieldValue = ''
    }) {
        const record = CaTools.copyObjectShallow(this.record);
        record[col.config.fieldId] = fieldValue;

        const validateResult = this.caFormLayoutValidator.validateCol({
            rowIdx: rowIdx,
            colIdx: colIdx,
            col:    col,
            type:   type,
            record: record
        });

        this.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:validateOnInput:response',
            {
                rowIdx:         rowIdx,
                colIdx:         colIdx,
                validateResult: validateResult
            }
        );
    }

    /**
     * Aktiviert die Ladeanzeige für das Formular.
     */
    receiveCaFormLayoutSetStatusLoadingResponse() {
        this.isLoading = true;

        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <ca-ui-loading-indicator></ca-ui-loading-indicator>
        `;

        const formElem = this.shadowRoot.querySelector('.ca-form-layout-form');
        formElem.appendChild(tmpl.content.cloneNode(true));
    }

    /**
     * Deaktiviert die Ladeanzeige für das Formular.
     */
    receiveCaFormLayoutSetStatusNotLoadingResponse() {
        this.isLoading = false;

        const formElem = this.shadowRoot.querySelector('.ca-form-layout-form');

        for (const childNode of formElem.childNodes) {
            if (childNode.nodeName === 'CA-UI-LOADING-INDICATOR') {
                formElem.removeChild(childNode);
                break;
            }
        }
    }

    /**
     * Die Formularkonfiguration wird von der einbindenden Komponente
     * gesendet.
     */
    receiveCaFormLayoutAttachedResponse({
        config = {},
        record = {}
    }) {
        this.config.align            = config?.align || 'left';
        this.config.borderRadius     = config?.borderRadius || false;
        this.config.maxWidth         = config?.maxWidth || 0;           // 0 = Verfügbare Breite einnehmen, ansonsten Breite in Pixel
        this.config.minWidth         = config?.minWidth || 0;           // 0 = Keine minimale Breite
        this.config.marginTop        = config?.marginTop || 0;
        this.config.padding          = config?.padding || '';
        this.config.layoutDefinition = config?.layoutDefinition || {};
        this.config.useShadow        = config?.useShadow || false;

        this.config.maxWidth > 0 ? this.configCss += 'max-width: ' + this.config.maxWidth + 'px;' : '';
        this.config.minWidth > 0 ? this.configCss += 'min-width: ' + this.config.minWidth + 'px;' : '';
        this.config.marginTop > 0 ? this.configCss += 'top: ' + this.config.marginTop + 'px;' : '';

        const formElem = this.shadowRoot.querySelector('form');
        formElem.style = this.configCss;
        formElem.classList.add(this.config.align);
        
        if (this.config.borderRadius) {
            formElem.classList.add('border-radius');
        }
        if (this.config.padding) {
            formElem.classList.add('padding-' + this.config.padding);
        }
        if (this.config.useShadow) {
            formElem.classList.add('use-shadow');
        }

        this.record = record;

        this.caFormLayoutValidator.setRecord({
            record: CaTools.copyObjectShallow(this.record)
        });

        if (config.style) {
            this.shadowRoot.firstChild.innerHTML += config.style;
        }

        this.hasLayoutDefinition = this.config.layoutDefinition.hasOwnProperty('rows') ? true : false;

        if (this.hasLayoutDefinition) {
            this.caFormLayoutValidator.setLayoutDefinition({
                layoutDefinition: this.config.layoutDefinition
            });
            this.parseLayoutDefinition();
            this.insertLayout();
            this.updateLayout();
        }
    }

    // ---------------------------------------------------------------------

    focusHandleStart() {
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-trapend');

        if (caFocusEndElem) {
            caFocusEndElem.focus();
        }
    }

    focusHandleEnd() {
        const caFocusStartElem = this.shadowRoot.querySelector('.ca-focus-trapstart');

        if (caFocusStartElem) {
            caFocusStartElem.focus();
        }
    }

    insertLayout() {
        const caFormLayoutElem = this.shadowRoot.querySelector('.ca-form-layout');
        const tmpl = document.createElement('template');
        let layoutStr = '';
        let rowIdx = 0;

        for (const row of this.config.layoutDefinition.rows) {
            layoutStr += `
                <div
                    class    = "ca-form-layout-row"
                    style    = "${row.parsedConfig.visibleCols ? row.parsedConfig.css : ''}"
                    row-idx  = "${rowIdx}"
                >
            `;

            let colIdx = 0;
            for (const col of row.cols) {
                layoutStr += `
                    <div
                        class       = "ca-form-layout-col"
                        style       = "${col.parsedConfig.css}"
                        row-col-idx = "${rowIdx}-${colIdx}"
                    >
                 `;

                layoutStr += `
                    <${col.elementType}
                        row-idx = "${rowIdx}"
                        col-idx = "${colIdx}"
                    ></${col.elementType}>
                `;

                layoutStr += `</div>`;
                colIdx++;
            }

            layoutStr += `</div>`;
            rowIdx++;
        }

        tmpl.innerHTML = layoutStr;
        caFormLayoutElem.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    /**
     * Aktualisiert das Layout gemäß den Darstellungsoptionen.
     */
    updateLayout() {
        const caFormLayoutElem = this.shadowRoot.querySelector('.ca-form-layout');
        let rowIdx = 0;

        for (const row of this.config.layoutDefinition.rows) {
            let colIdx = 0;

            for (const col of row.cols) {
                const colElem = caFormLayoutElem.shadowRoot.querySelector(`div[row-col-idx="${rowIdx}-${colIdx}"]`);

                row.parsedConfig.visibleCols = false;

                if (col.parsedConfig.hasOwnProperty('initialHeight') === false) {
                    col.parsedConfig.initialHeight = Math.ceil(colElem.getBoundingClientRect().height);
                }

                if (col.parsedConfig?.dspor) {
                    if (col.parsedConfig.dspor.hide) {
                        colElem.style['max-height'] = 0;
                        colElem.style.overflow      = 'hidden';
                    } else {
                        row.parsedConfig.visibleCols = true;

                        colElem.style['max-height'] = col.parsedConfig.initialHeight + 'px';
                        colElem.style.overflow      = 'initial';
                    }
                }

                colIdx++;
            }

            rowIdx++;
        }
    }

    /**
     * Berechnet Werte für die Layoutdefinition vor, um unnötige Bindings zu
     * verhindern.
     */
    parseLayoutDefinition() {
        for (const row of this.config.layoutDefinition.rows) {
            row.parsedConfig = {
                css:         '',
                visibleCols: true
            };

            row.config?.marginBottom ? row.parsedConfig.css += 'margin-bottom: ' + row.config.marginBottom + 'px;' : '';
            row.config?.marginTop    ? row.parsedConfig.css += 'margin-top: ' + row.config.marginTop + 'px;' : '';
            row.config?.wrap         ? row.parsedConfig.css += 'flex-wrap: wrap;' : '';

            for (const col of row.cols) {
                const initialHeight = col.parsedConfig?.initialHeight ? col.parsedConfig.initialHeight : 0;

                col.parsedConfig = {
                    css:           '',
                };

                if (initialHeight > 0) {
                    col.parsedConfig.initialHeight = initialHeight;
                }

                if (col.config.align === 'stretch') {
                    col.parsedConfig.css += 'flex: 1;';
                } else {
                    col.parsedConfig.css += 'flex: initial;';
                }

                if (col.config.maxWidth) {
                    col.parsedConfig.css += `max-width: ${col.config.maxWidth}px;`;
                }
                if (col.config.minWidth) {
                    col.parsedConfig.css += `min-width: ${col.config.minWidth}px;`;
                }
            }
        }
    }
}

customElements.define('ca-form-layout', CaFormLayout);

export {CaFormLayout};

import {CaToolEventAggregator} from '../../ca-tools/ca-tool-event-aggregator';

class CaStageController extends HTMLElement {
    constructor() {
        super();

        this.activeStage                   = {};
        this.activeStageIdx                = 0;
        this.caUiFatalErrorEventAggregator = new CaToolEventAggregator();
        this.fatalErrorDef                 = {
            error:     '',
            errortext: ''
        };
        this.stages                        = [];
        this.subscriptions                 = [];

        this._shadowRoot = this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();

        this.subscriptions.push(
            this.caUiFatalErrorEventAggregator.subscribe(
                'CaUiFatalError.config.set:request',
                (params) => this.answerCaUiFatalErrorConfigSetRequest(params)
            )
        );
        this.subscriptions.push(
            this.caUiFatalErrorEventAggregator.subscribe(
                'CaUiFatalError.close:request',
                () => this.answerCaUiFatalErrorCloseRequest()
            )
        );
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            .ca-stage {
                position:       absolute;
                overflow:       hidden;
                top:            0;
                bottom:         0;
                width:          100%;
                transform:      translate(-100%);
                display:        flex;
                flex-direction: column;
            }
            .ca-stage.active {
                transform: translate(0);
            }

            .ca-stage.animate-slidein-from-left {
                z-index:                   2;
                animation-duration:        0.5s;
                animation-name:            slideinfromleft;
                animation-iteration-count: 1;
            }
            .ca-stage.animate-slidein-from-right {
                z-index:                   2;
                animation-duration:        0.5s;
                animation-name:            slideinfromright;
                animation-iteration-count: 1;
            }
            .ca-stage.animate-slideout-to-left {
                z-index:                   1;
                animation-duration:        0.5s;
                animation-name:            slideouttoleft;
                animation-iteration-count: 1;
            }
            .ca-stage.animate-slideout-to-right {
                z-index:                   1;
                animation-duration:        0.5s;
                animation-name:            slideouttoright;
                animation-iteration-count: 1;
            }

            @keyframes slideinfromleft {
                from {
                    transform: translate(-100%);
                }

                to {
                    transform: translate(0);
                }
            }
            @keyframes slideinfromright {
                from {
                    transform: translate(100%);
                }

                to {
                    transform: translate(0);
                }
            }
            @keyframes slideouttoleft {
                from {
                    transform: translate(0);
                }

                to {
                    transform: translate(-100%);
                }
            }
            @keyframes slideouttoright {
                from {
                    transform: translate(0);
                }

                to {
                    transform: translate(100%);
                }
            }
            @keyframes slideOutToTop {
                from {
                    transform: translateY(0);
                }

                to {
                    transform: translateY(-44px);
                }
            }
        `;

        this._shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = ``;

        this._shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    // ---------------------------------------------------------------------

    answerCaStageControllerAddStageRequest({
        id            = '',
        stageElement  = '',
        gotoAnimation = 'slide'
    }) {
        let stageExists = false;

        for (const stage of this.stages) {
            if (stage.id === id) {
                stageExists = true;
                break;
            }
        }

        if (stageExists === false) {
            this.stages.push({
                id:            id,
                stageElement:  stageElement,
                gotoAnimation: gotoAnimation
            });

            const tmpl = document.createElement('template');
            tmpl.innerHTML = `
                <div class="ca-stage">
                    <${stageElement}></${stageElement}>
                </div>
            `;
            this._shadowRoot.appendChild(tmpl.content.cloneNode(true));
        }
    }

    answerCaStageControllerGotoStageRequest({
        id         = '',
        killStages = [],
        backwards  = false,
        params     = {}
    }) {
        // Animation für vorhandenen aktiven Stage einrichten
        let activeStageFound = false;
        let activeStageIdx   = 0;

        if (this.activeStage.id) {
            for (const stage of this.stages) {
                if (stage.id === this.activeStage.id) {
                    activeStageFound = true;
                    break;
                }
                activeStageIdx++;
            }
        }

        // Den neuen aktiven Stage animieren
        let stageIdx   = 0;
        let stageFound = false;

        for (const stage of this.stages) {
            if (stage.id === id) {
                this.activeStage    = stage;
                this.activeStageIdx = stageIdx;
                stageFound          = true;
                break;
            }
            stageIdx++;
        }

        if (stageFound) {
            window.document.title = 'Rezepte - ' + this.activeStage.id;
            window.history.pushState(
                {},
                this.activeStage.id,
                window.location.origin + '#/' + this.activeStage.id
            );

            const stageElems   = this._shadowRoot.querySelectorAll('.ca-stage');
            let   inAnimation  = 'animate-slidein-from-right';
            let   outAnimation = 'animate-slideout-to-left';

            if (activeStageFound) {
                if (backwards === true) {
                    inAnimation  = 'animate-slidein-from-left';
                    outAnimation = 'animate-slideout-to-right';
                }

                stageElems[activeStageIdx].addEventListener('animationend', () => {
                    stageElems[activeStageIdx].classList.remove(outAnimation);
                    stageElems[activeStageIdx].classList.remove('active');
                }, {
                    once: true
                });
                stageElems[activeStageIdx].classList.add(outAnimation);
            }

            let nodeIdx   = 0;
            let childNode = stageElems[stageIdx].childNodes[nodeIdx];

            while (childNode.nodeName === '#text') {
                nodeIdx++;
                childNode = stageElems[stageIdx].childNodes[nodeIdx];
            }
            
            if (childNode?.stageEventAggregator) {
                childNode.stageEventAggregator.publish(
                    'CaStageController:stageActivated:request',
                    params
                );
            }

            stageElems[stageIdx].addEventListener('animationend', () => {
                stageElems[stageIdx].classList.add('active');
                stageElems[stageIdx].classList.remove(inAnimation);

                if (killStages.length > 0) {
                    for (const killStage of killStages) {
                        this.removeStage({
                            id: killStage
                        });
                    }
                }
            }, {
                once: true
            });
            stageElems[stageIdx].classList.add(inAnimation);
        }
    }

    answerCaStageControllerShowFatalErrorRequest({
        error     = '',
        errortext = '',
        config    = {}
    }) {
        this.fatalErrorDef.error     = error;
        this.fatalErrorDef.errortext = errortext;
        this.fatalErrorDef.config    = config;

        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <ca-ui-fatal-error></ca-ui-fatal-error>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    answerCaUiFatalErrorCloseRequest() {
        let fatalErrorElem = this.shadowRoot.querySelector('ca-ui-fatal-error');

        this.shadowRoot.removeChild(fatalErrorElem);
    }

    answerCaUiFatalErrorConfigSetRequest() {
        this.caUiFatalErrorEventAggregator.publish(
            'CaUiFatalError.config.set:response',
            {
                ws: {
                    error:     this.fatalErrorDef.error,
                    errortext: this.fatalErrorDef.errortext,
                    config:    this.fatalErrorDef.config
                }
            }
        );
    }

    removeStage({
        id = ''
    }) {
        let stageIdx   = 0;
        let stageFound = false;

        for (const stage of this.stages) {
            if (stage.id === id) {
                stageFound = true;
                break;
            }
            stageIdx++;
        }

        if (stageFound) {
            this.stages.splice(stageIdx, 1);

            const stageElems = this._shadowRoot.querySelectorAll('.ca-stage');
            this._shadowRoot.removeChild(stageElems[stageIdx]);

            // TODO: aktive Klasse setzen und animieren...
        }
    }
}

customElements.define('ca-stage-controller', CaStageController);

export {CaStageController};

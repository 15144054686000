import {CaFormLayoutValidator} from '../ca-form-layout-validator';
import {CaToolEventAggregator} from '../../../../ca-tools/ca-tool-event-aggregator';

class CaFormFieldCheckbox extends HTMLElement {
    constructor() {
        super();

        this.caFormLayoutValidator                = new CaFormLayoutValidator();
        this.caFormFieldValidationEventAggregator = new CaToolEventAggregator();
        this.config                               = {};
        this.host                                 = this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host;
        this.subscriptions                        = [];
        this.validationMessages                   = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }
            :host {
                font-family:   var(--ca-font-family);
                outline:       none;
                display:       flex;
                flex:          1;
                margin-bottom: 4px;
            }
            .ca-form-field-container {
                display:        flex;
                flex-direction: row;
                align-items:    flex-start;
                margin-right:   8px;
            }
            input[type="checkbox"] {
                position:   absolute;
                width:      0;
                height:     0;
                visibility: hidden;
            }
            label {
                white-space: pre;
                color:       var(--ca-ui-form-label--fg);
                cursor:      pointer;
                margin-top:  3px;
            }
            input:checked ~ .check {
                border-color: var(--ca-ui-form-label--fg);
            }
            input:checked ~ .check::after {
                opacity:   1;
                transform: scale(1);
            }
            input:focus ~ .check {
                background-color: var(--ca-ui-form-input--bg-focus);
                border-color:     var(--ca-ui-form-input--border-color-focus);
            }
            input:focus ~ .check::after {
                color: var(--ca-ui-form-input--fg-focus);
            }
            .check {
                position:         relative;
                min-width:        20px;
                height:           20px;
                display:          flex;
                flex:             initial;
                align-items:      center;
                justify-content:  center;
                background-color: var(--ca-ui-form-input--bg);
                border-radius:    2px;
                border:           1px solid var(--ca-ui-form-input--border-color);
                transition:       all 0.15s cubic-bezier(0, 1.05, 0.72, 1.07);
                margin-right:     8px;
            }
            .check::after {
                content:         "\\e116";
                width:           100%;
                height:          100%;
                opacity:         0;
                z-index:         4;
                position:        absolute;
                display:         flex;
                flex-direction:  column;
                align-items:     center;
                justify-content: center;
                transform:       scale(0);
                font-family:     icomoon;
                color:           var(--ca-ui-form-input--fg);
                transition:      all 0.25s cubic-bezier(0, 1.05, 0.72, 1.07);
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div
                class="ca-form-field ca-form-field-checkbox"
            >
                <div class="ca-form-field-container">
                    <input type="checkbox" tabindex="2">
                    <span class="check"></span>
                    <label></label>
                </div>
                <ca-form-field-validation-messages></ca-form-field-validation-messages>
            </div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:attached:response',
                (params) => this.receiveCaFormLayoutChildAttachedResponse(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:updateRecord:request',
                (params) => this.receiveCaFormLayoutUpdateRecordRequest(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:validation.result.request',
                (params) => this.answerCaFormLayoutValidationResultRequest(params)
            )
        );
        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:attached:request',
            {
                elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                elColIdx: parseInt(this.getAttribute('col-idx'), 10)
            }
        );
    }

    disconnectedCallback() {
        const fieldInput = this.shadowRoot.querySelector('input');
        fieldInput.removeEventListener(
            'change',
            this.eventListenerFieldInputChange
        );
    }

    // ---------------------------------------------------------------------

    answerCaFormLayoutValidationResultRequest(validationMessages) {
        const rowIdx = parseInt(this.getAttribute('row-idx'), 10);
        const colIdx = parseInt(this.getAttribute('col-idx'), 10);

        this.validationMessages = [];

        for (const validationMessage of validationMessages) {
            if (
                validationMessage.rowIdx === rowIdx &&
                validationMessage.colIdx === colIdx
            ) {
                this.validationMessages.push({
                    type:                 validationMessage.type,
                    validationErrorPoint: validationMessage.validationErrorPoint,
                    message:              validationMessage.message
                });
            }
        }

        this.updateValidationMessages();
    }

    receiveCaFormLayoutUpdateRecordRequest({
        record  = {}
    }) {
        if (record.hasOwnProperty(this.config.fieldId)) {
            const fieldInput = this.shadowRoot.querySelector('input');

            this.value         = record[this.config.fieldId];
            fieldInput.checked = record[this.config.fieldId];
        }
    }

    receiveCaFormLayoutChildAttachedResponse({
        rowIdx = 0,
        colIdx = 0,
        config = {},
        value  = false
    }) {
        if (
            rowIdx === parseInt(this.getAttribute('row-idx'), 10) &&
            colIdx === parseInt(this.getAttribute('col-idx'), 10)
        ) {
            this.config.label      = config?.label      || '';
            this.config.fieldId    = config?.fieldId    || '';

            const fieldLabel = this.shadowRoot.querySelector('label');
            fieldLabel.innerHTML = this.config.label;
            fieldLabel.setAttribute('for', this.config.fieldId);

            const fieldInput = this.shadowRoot.querySelector('input');
            fieldInput.setAttribute('id', this.config.fieldId);

            this.value = value;
            fieldInput.checked = this.value;

            this.eventListenerFieldInputChange = () => this.receiveUserInputOnChange();
            fieldInput.addEventListener(
                'change',
                this.eventListenerFieldInputChange
            );
        }
    }

    // ---------------------------------------------------------------------

    receiveUserInputOnChange() {
        const fieldInput = this.shadowRoot.querySelector('input');

        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:userInput:request',
            {
                rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                fieldInfo:  this.config.fieldInfo,
                fieldId:    this.config.fieldId,
                fieldValue: fieldInput.checked
            }
        );
    }

    updateValidationMessages() {
        const messageContainer = this.shadowRoot.querySelector('.ca-form-field-validation-messages');
        let validationStr = '';

        for (const validationMessage of this.validationMessages) {
            validationStr += `
                <div class="ca-validation-message ${validationMessage.type}">
                    <i>${validationMessage.type === 'warning' ? '🛈' : '⚠'}</i>
                    <span>${validationMessage.message}</span>
                </div>
            `;
        }

        messageContainer.innerHTML = validationStr;
    }
}

customElements.define('ca-form-field-checkbox', CaFormFieldCheckbox);

export {CaFormFieldCheckbox};

import {CaToolQuery}           from '../ca-tools/ca-tool-query.js';
import {CaToolEventAggregator} from '../ca-tools/ca-tool-event-aggregator';
import {CaAppConfig}           from '../ca-app/ca-app-config';
import {CaAppEventAggregators} from '../ca-app/ca-app-event-aggregators';

class RezeptDeletePage extends HTMLElement {

    constructor() {
        super();

        this.caFormLayoutEventAggregator     = new CaToolEventAggregator();
        this.caUiNotificationEventAggregator = new CaToolEventAggregator();
        this.rezeptId                        = null;
        this.stageEventAggregator            = new CaToolEventAggregator();
        this.subscriptions                   = [];

        this.subscriptions.push(
            this.stageEventAggregator.subscribe(
                'CaStageController:stageActivated:request',
                (params) => this.answerCaStageControllerStageActivatedRequest(params)
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:attached:request',
                () => this.answerCaFormLayoutAttachedRequest()
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:submit:request',
                (params) => this.answerCaFormLayoutSubmitRequest(params)
            )
        );

        this.attachShadow({mode: 'open'});

        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                position: relative;
                flex:     1;
                overflow: hidden;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <ca-ui-notification></ca-ui-notification>
            <ca-form-layout></ca-form-layout>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    answerCaStageControllerStageActivatedRequest(params) {
        this.rezeptId = params.id;

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__status'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.addItem:request',
            {
                sectionCenter: [
                    {
                        id:         '__status',
                        type:       'text',
                        label:      'Rezept löschen',
                        fontWeight: 500
                    }
                ]
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'right',
                id:      '__edit'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'right',
                id:      '__delete'
            }
        );

        const sectionRight = [];

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.config.set:response',
            {
                sectionLeft: [
                    {
                        type:     'button',
                        id:       '__back',
                        label:    'Abbrechen',
                        icon:     'ca-icon-chevron-thin-left',
                        callback: () => {
                            this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');

                            CaAppEventAggregators.eventAggregatorToolbar.publish(
                                'toolbar.update.deleteItem:request',
                                {
                                    section: 'left',
                                    id:      '__back'
                                }
                            );
                            CaAppEventAggregators.eventAggregatorStage.publish(
                                'CaStageController:gotoStage:request',
                                {
                                    id:        'rezept',
                                    backwards: true,
                                    params:    {
                                        id: params.id
                                    }
                                }
                            );
                        }
                    }
                ],
                sectionRight: sectionRight
            }
        );
    }

    async answerCaFormLayoutAttachedRequest() {
        this.caFormLayoutEventAggregator.publish(
            'CaFormLayout:attached:response',
            {
                config: {
                    marginTop: 10,
                    padding:   'medium',
                    style:     `
                        .ca-form-layout {
                            flex: initial;
                        }
                    `,
                    layoutDefinition: {
                        rows: [
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-button-submit',
                                        config: {
                                            align: 'stretch',
                                            title: 'Rezept endgültig löschen'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                },
                record: {}
            }
        );
    }

    async answerCaFormLayoutSubmitRequest() {
        this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusLoading:response');

        const ws = await CaToolQuery.deleteRezept({
            rezeptId: this.rezeptId
        });

        if (ws.error === '0') {
            setTimeout(
                () => {
                    this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');

                    CaAppEventAggregators.eventAggregatorToolbar.publish(
                        'toolbar.update.deleteItem:request',
                        {
                            section: 'left',
                            id:      '__back'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:gotoStage:request',
                        {
                            id:        'rezepte',
                            backwards: true,
                            params: {
                                reload: true
                            }
                        }
                    );
                },
                50
            );
        } else {
            this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');
            const errorDef = {
                error:     ws.error,
                errortext: ws.errortext || 'Fehler beim Speichern',
                config:    {
                    allowClose: true
                }
            };

            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:showFatalError:request',
                errorDef
            );
        }
    }
}

customElements.define('rezept-delete-page', RezeptDeletePage);

export {RezeptDeletePage};

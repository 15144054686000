/**
 * Stellt einen Bus für Benachrichtigungen zwischen Komponenten zur Verfügung.
 */
class CaToolEventAggregator {

    constructor() {
        this.subscribers = {};
    }

    publish(eventName, data) {
        if (!Array.isArray(this.subscribers[eventName])) {
            return;
        }
        this.subscribers[eventName].forEach((callback) => {
            callback(data);
        })
    }
    
    publishRequest(eventName, data) {
        return new Promise(
            (resolve, reject) =>
            {
                if (!Array.isArray(this.subscribers[eventName])) {
                    resolve();
                }

                this.subscribers[eventName].forEach((callback) => {
                    resolve(
                        callback(data)
                    );
                })
            }
        );
    }

    subscribe(eventName, callback) {
        if (!Array.isArray(this.subscribers[eventName])) {
            this.subscribers[eventName] = [];
        }

        this.subscribers[eventName].push(callback);

        const index = this.subscribers[eventName].length - 1;

        return {
            unsubscribe: () => {
                this.subscribers[eventName].splice(index, 1);
            }
        };
    }
}

export {CaToolEventAggregator};

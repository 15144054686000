import {CaToolQuery}           from '../ca-tools/ca-tool-query.js';
import {CaToolEventAggregator} from '../ca-tools/ca-tool-event-aggregator';
import {CaAppConfig}           from '../ca-app/ca-app-config';
import {CaAppEventAggregators} from '../ca-app/ca-app-event-aggregators';

class RezeptCreatePage extends HTMLElement {

    constructor() {
        super();

        this.caFormLayoutEventAggregator     = new CaToolEventAggregator();
        this.caUiNotificationEventAggregator = new CaToolEventAggregator();
        this.stageEventAggregator            = new CaToolEventAggregator();
        this.subscriptions                   = [];

        this.subscriptions.push(
            this.stageEventAggregator.subscribe(
                'CaStageController:stageActivated:request',
                (params) => this.answerCaStageControllerStageActivatedRequest(params)
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:attached:request',
                () => this.answerCaFormLayoutAttachedRequest()
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:submit:request',
                (params) => this.answerCaFormLayoutSubmitRequest(params)
            )
        );

        this.attachShadow({mode: 'open'});

        this.setStyle();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                position: relative;
                flex:     1;
                overflow: hidden;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <ca-ui-notification></ca-ui-notification>
            <ca-form-layout></ca-form-layout>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    answerCaStageControllerStageActivatedRequest(params) {
        this.rezeptId = params.id;

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__status'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.addItem:request',
            {
                sectionCenter: [
                    {
                        id:         '__status',
                        type:       'text',
                        label:      'Rezept erstellen',
                        fontWeight: 500
                    }
                ]
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'right',
                id:      '__edit'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'right',
                id:      '__delete'
            }
        );

        const sectionRight = [];

        sectionRight.push({
            type:     'button',
            id:       '__save',
            label:    'Speichern',
            icon:     'ca-icon-check',
            callback: () => {
                this.shadowRoot.querySelector('ca-form-layout').caFormLayoutEventAggregator.publish(
                    'CaFormLayout:submit:request'
                );
                /*
                CaAppEventAggregators.eventAggregatorStage.publish(
                    'CaStageController:gotoStage:request',
                    {
                        id:        'rezepte',
                        backwards: true
                    }
                );
                */
            }
        });

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.config.set:response',
            {
                sectionLeft: [
                    {
                        type:     'button',
                        id:       '__back',
                        label:    'Abbrechen',
                        icon:     'ca-icon-chevron-thin-left',
                        callback: () => {
                            this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');

                            CaAppEventAggregators.eventAggregatorToolbar.publish(
                                'toolbar.update.deleteItem:request',
                                {
                                    section: 'right',
                                    id:      '__save'
                                }
                            );
                            CaAppEventAggregators.eventAggregatorToolbar.publish(
                                'toolbar.update.deleteItem:request',
                                {
                                    section: 'left',
                                    id:      '__back'
                                }
                            );
                            CaAppEventAggregators.eventAggregatorStage.publish(
                                'CaStageController:gotoStage:request',
                                {
                                    id:        'rezepte',
                                    backwards: true
                                }
                            );
                        }
                    }
                ],
                sectionRight: sectionRight
            }
        );

        this.setTemplate();
    }

    async answerCaFormLayoutAttachedRequest() {
        this.caFormLayoutEventAggregator.publish(
            'CaFormLayout:attached:response',
            {
                config: {
                    marginTop: 10,
                    padding:   'medium',
                    style:     `
                        :host {
                        }
                    `,
                    layoutDefinition: {
                        rows: [
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-input',
                                        config: {
                                            align:            'stretch',
                                            label:            'Name',
                                            fieldId:          '__name',
                                            validateOnSubmit: {
                                                notEmpty: true,
                                            },
                                            validateOnInput: {
                                                noSurroundingWhitespace: true
                                            },
                                            focus: true
                                        }
                                    }
                                ]
                            },
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-textarea',
                                        config: {
                                            align:   'stretch',
                                            label:   'Beschreibung',
                                            fieldId: '__beschreibung',
                                            rows:    12
                                        }
                                    }
                                ]
                            },
                            {
                                config: {
                                    wrap: true
                                },
                                cols: [
                                    {
                                        elementType: 'ca-form-field-checkbox',
                                        config: {
                                            align:    'stretch',
                                            label:    'Glutenfrei',
                                            fieldId:  '__kat_glutenfrei',
                                            minWidth: 130,
                                            maxWidth: 130
                                        }
                                    },
                                    {
                                        elementType: 'ca-form-field-checkbox',
                                        config: {
                                            align:    'stretch',
                                            label:    'All in one',
                                            fieldId:  '__kat_allinone',
                                            minWidth: 130,
                                            maxWidth: 130
                                        }
                                    },
                                    {
                                        elementType: 'ca-form-field-checkbox',
                                        config: {
                                            align:    'stretch',
                                            label:    'Thermomix',
                                            fieldId:  '__kat_thermomix',
                                            minWidth: 130,
                                            maxWidth: 130
                                        }
                                    },
                                    {
                                        elementType: 'ca-form-field-checkbox',
                                        config: {
                                            align:    'stretch',
                                            label:    'Überbacken',
                                            fieldId:  '__kat_ueberbacken',
                                            minWidth: 130,
                                            maxWidth: 130
                                        }
                                    },
                                    {
                                        elementType: 'ca-form-field-checkbox',
                                        config: {
                                            align:    'stretch',
                                            label:    'Vegan',
                                            fieldId:  '__kat_vegan',
                                            minWidth: 130,
                                            maxWidth: 130
                                        }
                                    },
                                    {
                                        elementType: 'ca-form-field-checkbox',
                                        config: {
                                            align:    'stretch',
                                            label:    'Dörrgerät',
                                            fieldId:  '__kat_doerrgeraet',
                                            minWidth: 130,
                                            maxWidth: 130
                                        }
                                    },
                                    {
                                        elementType: 'ca-form-field-checkbox',
                                        config: {
                                            align:    'stretch',
                                            label:    'Putzmittel',
                                            fieldId:  '__kat_putzmittel',
                                            minWidth: 130,
                                            maxWidth: 130
                                        }
                                    }
                                ]
                            },
                            {
                                config: {
                                    marginTop: 17
                                },
                                cols: [
                                    {
                                        elementType: 'ca-form-field-select',
                                        config: {
                                            align:   'stretch',
                                            label:   'Art',
                                            fieldId: '__art_id',
                                            options: CaAppConfig.getArten()
                                        }
                                    }
                                ]
                            },
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-input',
                                        config: {
                                            align:   'stretch',
                                            label:   'Portionen',
                                            fieldId: '__portionen'
                                        }
                                    }
                                ]
                            },
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-textarea',
                                        config: {
                                            align:   'stretch',
                                            label:   'Zutaten',
                                            fieldId: '__zutaten',
                                            rows:    12
                                        }
                                    }
                                ]
                            },
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-textarea',
                                        config: {
                                            align:   'stretch',
                                            label:   'Schritte',
                                            fieldId: '__schritte',
                                            rows:    12
                                        }
                                    }
                                ]
                            },
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-input',
                                        config: {
                                            align:   'stretch',
                                            label:   'Quelle',
                                            fieldId: '__quelle'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                },
                record: {
                    __name:            '',
                    __beschreibung:    '',
                    __kat_glutenfrei:  false,
                    __kat_allinone:    false,
                    __kat_thermomix:   false,
                    __kat_ueberbacken: false,
                    __kat_vegan:       false,
                    __kat_doerrgeraet: false,
                    __kat_putzmittel:  false,
                    __quelle:          '',
                    __schritte:        '',
                    __zutaten:         '',
                    __portionen:       '',
                    __art_id:          '',
                    __bilder:          ''
                }
            }
        );
    }

    async answerCaFormLayoutSubmitRequest({
        record = {}
    }) {
        this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusLoading:response');

        const ws = await CaToolQuery.createRezept({
            rezept: record
        });

        if (ws.error === '0') {
            setTimeout(
                () => {
                    this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');

                    CaAppEventAggregators.eventAggregatorToolbar.publish(
                        'toolbar.update.deleteItem:request',
                        {
                            section: 'right',
                            id:      '__save'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorToolbar.publish(
                        'toolbar.update.deleteItem:request',
                        {
                            section: 'left',
                            id:      '__back'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:gotoStage:request',
                        {
                            id:        'rezepte',
                            backwards: true,
                            params: {
                                reload: true
                            }
                        }
                    );
                },
                50
            );
        } else {
            this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');
            const errorDef = {
                error:     ws.error,
                errortext: ws.errortext || 'Fehler beim Speichern',
                config:    {
                    allowClose: true
                }
            };

            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:showFatalError:request',
                errorDef
            );
        }
    }
}

customElements.define('rezept-create-page', RezeptCreatePage);

export {RezeptCreatePage};

import {CaFormLayoutValidator} from '../ca-form-layout-validator';
import {CaToolEventAggregator} from '../../../../ca-tools/ca-tool-event-aggregator';

class CaFormFieldSelect extends HTMLElement {
    constructor() {
        super();

        this.caFormLayoutValidator                = new CaFormLayoutValidator();
        this.caFormFieldValidationEventAggregator = new CaToolEventAggregator();
        this.config                               = {};
        this.host                                 = this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host;
        this.subscriptions                        = [];
        this.validationMessages                   = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }
            :host {
                font-family: var(--ca-font-family);
                display:     flex;
                flex:        1;
            }
            .ca-form-field {
                margin-bottom: 24px;
            }
            .ca-form-field-select {
                display:        flex;
                flex:           1;
                flex-direction: column;
                flex-wrap:      wrap;
            }
            .ca-form-field-select label {
                display:       flex;
                flex:          initial;
                font-size:     var(--ca-font-size--small);
                font-weight:   var(--ca-ui-form-label--font-weight);
                margin-bottom: 5px;
                color:         var(--ca-ui-form-label--fg);
            }
            .ca-form-field-container {
                display: flex;
                flex:    1;
            }
             .ca-form-field-container select {
                flex:                    1;
                min-height:              var(--ca-ui-form-input--min-height);
                font-size:               var(--ca-ui-font-size--form-input);
                line-height:             100%;
                border:                  none;
                border-bottom:           1px var(--ca-ui-form-input--border-color) solid;
                border-top-left-radius:  3px;
                border-top-right-radius: 3px;
                color:                   var(--ca-ui-form-input--fg);
                background-color:        var(--ca-ui-form-input--bg);
                padding-left:            8px;
                padding-right:           8px;
            }
            .ca-form-field-container select:focus {
                color:            var(--ca-ui-form-input--fg-focus);
                background-color: var(--ca-ui-form-input--bg-focus);
                border-color:     var(--ca-ui-form-input--border-color-focus);
            }
            .ca-form-field-container select.bordered {
                border:        1px var(--ca-ui-form-input--border-color) solid;
                border-radius: 3px;
            }
            .ca-form-field-container select.bordered:focus {
                border-color: var(--ca-ui-form-input--border-color-focus);
            }
            .ca-form-field-container select.bordered.has-validation-messages {
                border-bottom-left-radius:  0;
                border-bottom-right-radius: 0;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div
                class="ca-form-field ca-form-field-select"
            >
                <label></label>
                <div class="ca-form-field-container">
                    <select tabindex="2"></select>
                </div>
                <ca-form-field-validation-messages></ca-form-field-validation-messages>
            </div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:attached:response',
                (params) => this.receiveCaFormLayoutChildAttachedResponse(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:updateRecord:request',
                (params) => this.receiveCaFormLayoutUpdateRecordRequest(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:validation.result.request',
                (params) => this.answerCaFormLayoutValidationResultRequest(params)
            )
        );
        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:attached:request',
            {
                elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                elColIdx: parseInt(this.getAttribute('col-idx'), 10)
            }
        );
    }

    disconnectedCallback() {
        const fieldInput = this.shadowRoot.querySelector('input');
        fieldInput.removeEventListener(
            'change',
            this.eventListenerFieldInputChange
        );
    }

    // ---------------------------------------------------------------------

    answerCaFormLayoutValidationResultRequest(validationMessages) {
        const rowIdx = parseInt(this.getAttribute('row-idx'), 10);
        const colIdx = parseInt(this.getAttribute('col-idx'), 10);

        this.validationMessages = [];

        for (const validationMessage of validationMessages) {
            if (
                validationMessage.rowIdx === rowIdx &&
                validationMessage.colIdx === colIdx
            ) {
                this.validationMessages.push({
                    type:                 validationMessage.type,
                    validationErrorPoint: validationMessage.validationErrorPoint,
                    message:              validationMessage.message
                });
            }
        }

        this.updateValidationMessages();
    }

    receiveCaFormLayoutUpdateRecordRequest({
        record  = {}
    }) {
        if (record.hasOwnProperty(this.config.fieldId)) {
            const fieldSelect = this.shadowRoot.querySelector('select');

            this.value        = record[this.config.fieldId];
            fieldSelect.value = record[this.config.fieldId];
        }
    }

    receiveCaFormLayoutChildAttachedResponse({
        rowIdx = 0,
        colIdx = 0,
        config = {},
        value  = false
    }) {
        if (
            rowIdx === parseInt(this.getAttribute('row-idx'), 10) &&
            colIdx === parseInt(this.getAttribute('col-idx'), 10)
        ) {
            this.config.label      = config?.label      || '';
            this.config.fieldId    = config?.fieldId    || '';

            const fieldLabel = this.shadowRoot.querySelector('label');
            fieldLabel.innerHTML = this.config.label;
            fieldLabel.setAttribute('for', this.config.fieldId);

            const fieldSelect = this.shadowRoot.querySelector('select');
            fieldSelect.setAttribute('id', this.config.fieldId);

            if (config.options) {
                for (const option of config.options) {
                    const tmpl = document.createElement('template');
                    tmpl.innerHTML = `<option value="${option.id}">${option.name}</option>`;

                    fieldSelect.appendChild(tmpl.content.cloneNode(true));
                }
            }

            this.value = value;
            fieldSelect.value = this.value;

            this.eventListenerFieldInputChange = () => this.receiveUserInputOnChange();
            fieldSelect.addEventListener(
                'change',
                this.eventListenerFieldInputChange
            );
        }
    }

    // ---------------------------------------------------------------------

    receiveUserInputOnChange() {
        const fieldSelect = this.shadowRoot.querySelector('select');

        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:userInput:request',
            {
                rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                fieldInfo:  this.config.fieldInfo,
                fieldId:    this.config.fieldId,
                fieldValue: fieldSelect.value
            }
        );
    }

    updateValidationMessages() {
        const messageContainer = this.shadowRoot.querySelector('.ca-form-field-validation-messages');
        let validationStr = '';

        for (const validationMessage of this.validationMessages) {
            validationStr += `
                <div class="ca-validation-message ${validationMessage.type}">
                    <i>${validationMessage.type === 'warning' ? '🛈' : '⚠'}</i>
                    <span>${validationMessage.message}</span>
                </div>
            `;
        }

        messageContainer.innerHTML = validationStr;
    }
}

customElements.define('ca-form-field-select', CaFormFieldSelect);

export {CaFormFieldSelect};

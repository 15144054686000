import {CaFormLayoutValidator} from '../ca-form-layout-validator';
import {CaToolEventAggregator} from '../../../../ca-tools/ca-tool-event-aggregator';

class CaFormFieldTextarea extends HTMLElement {
    constructor() {
        super();

        this.caFormLayoutValidator                = new CaFormLayoutValidator();
        this.caFormFieldValidationEventAggregator = new CaToolEventAggregator();
        this.config                               = {};
        this.host                                 = this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host;
        this.subscriptions                        = [];
        this.validationMessages                   = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }
            :host {
                font-family: var(--ca-font-family);
                display:     flex;
                flex:        1;
            }
            .ca-form-field {
                margin-bottom: 24px;
            }
            .ca-form-field-textarea {
                display:        flex;
                flex:           1;
                flex-direction: column;
                flex-wrap:      wrap;
            }
            .ca-form-field-textarea label {
                display:       flex;
                flex:          initial;
                font-size:     var(--ca-font-size--small);
                font-weight:   var(--ca-ui-form-label--font-weight);
                margin-bottom: 5px;
                color:         var(--ca-ui-form-label--fg);
            }
            .ca-form-field-container {
                display: flex;
                flex:    1;
            }
            .ca-form-field-container textarea {
                flex:                    1;
                min-height:              var(--ca-ui-form-input--min-height);
                font-size:               var(--ca-ui-font-size--form-input);
                line-height:             100%;
                border:                  none;
                border-bottom:           1px var(--ca-ui-form-input--border-color) solid;
                border-top-left-radius:  3px;
                border-top-right-radius: 3px;
                color:                   var(--ca-ui-form-input--fg);
                background-color:        var(--ca-ui-form-input--bg);
                padding-left:            8px;
                padding-right:           8px;
            }
            .ca-form-field-container textarea:focus {
                color:            var(--ca-ui-form-input--fg-focus);
                background-color: var(--ca-ui-form-input--bg-focus);
                border-color:     var(--ca-ui-form-input--border-color-focus);
            }
            .ca-form-field-container textarea.bordered {
                border:        1px var(--ca-ui-form-input--border-color) solid;
                border-radius: 3px;
            }
            .ca-form-field-container textarea.bordered:focus {
                border-color: var(--ca-ui-form-input--border-color-focus);
            }
            .ca-form-field-container textarea.bordered.has-validation-messages {
                border-bottom-left-radius:  0;
                border-bottom-right-radius: 0;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div
                class="ca-form-field ca-form-field-textarea"
            >
                <label></label>
                <div class="ca-form-field-container">
                    <textarea
                        tabindex="2"
                    ></textarea>
                </div>
                <ca-form-field-validation-messages></ca-form-field-validation-messages>
            </div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const fieldInput = this.shadowRoot.querySelector('textarea');
        fieldInput.addEventListener(
            'keydown',
            this.eventListenerFieldInputKeydown
        );
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:attached:response',
                (params) => this.receiveCaFormLayoutChildAttachedResponse(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:updateRecord:request',
                (params) => this.receiveCaFormLayoutUpdateRecordRequest(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:validation.result.request',
                (params) => this.answerCaFormLayoutValidationResultRequest(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:validateOnInput:response',
                (params) => this.receiveCaFormLayoutChildValidateOnInputResponse(params)
            )
        );
        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:attached:request',
            {
                elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                elColIdx: parseInt(this.getAttribute('col-idx'), 10)
            }
        );
    }

    disconnectedCallback() {
        const fieldInput = this.shadowRoot.querySelector('input');
        fieldInput.removeEventListener(
            'change',
            this.eventListenerFieldInputChange
        );
        fieldInput.removeEventListener(
            'keydown',
            this.eventListenerFieldInputKeydown
        );
        if (this.config.hasOwnProperty('validateOnInput')) {
            fieldInput.removeEventListener(
                'input',
                this.eventListenerFieldInputInput
            );
        }
    }

    // ---------------------------------------------------------------------

    answerCaFormLayoutValidationResultRequest(validationMessages) {
        const rowIdx = parseInt(this.getAttribute('row-idx'), 10);
        const colIdx = parseInt(this.getAttribute('col-idx'), 10);

        this.validationMessages = [];

        for (const validationMessage of validationMessages) {
            if (
                validationMessage.rowIdx === rowIdx &&
                validationMessage.colIdx === colIdx
            ) {
                this.validationMessages.push({
                    type:                 validationMessage.type,
                    validationErrorPoint: validationMessage.validationErrorPoint,
                    message:              validationMessage.message
                });
            }
        }

        const fieldInput = this.shadowRoot.querySelector('input');

        if (this.validationMessages.length === 0) {
            fieldInput.classList.remove('has-validation-messages');
        } else {
            fieldInput.classList.add('has-validation-messages');
        }

        this.caFormFieldValidationEventAggregator.publish(
            'CaFormFieldValidationMessages:update:request',
            {
                validationMessages: this.validationMessages
            }
        );
    }

    receiveCaFormLayoutUpdateRecordRequest({
        record  = {}
    }) {
        if (record.hasOwnProperty(this.config.fieldId)) {
            const fieldInput = this.shadowRoot.querySelector('textarea');

            this.value       = record[this.config.fieldId];
            fieldInput.value = record[this.config.fieldId];
        }
    }

    receiveCaFormLayoutChildAttachedResponse({
        rowIdx = 0,
        colIdx = 0,
        config = {},
        value = ''
    }) {
        if (
            rowIdx === parseInt(this.getAttribute('row-idx'), 10) &&
            colIdx === parseInt(this.getAttribute('col-idx'), 10)
        ) {
            this.config.align    = config?.align      || 'stretch';
            this.config.label    = config?.label      || '';
            this.config.fieldId  = config?.fieldId    || '';
            this.config.type     = config?.type       || 'text';
            this.config.bordered = config?.bordered   || false;
            this.config.rows     = config?.rows       || 5;

            if (config.validateOnInput) {
                this.config.validateOnInput = config.validateOnInput;
            }

            const fieldLabel = this.shadowRoot.querySelector('label');
            fieldLabel.innerHTML = this.config.label + ':';
            fieldLabel.setAttribute('for', this.config.fieldId);

            const fieldInput = this.shadowRoot.querySelector('textarea');
            fieldInput.setAttribute('id',   this.config.fieldId);
            fieldInput.setAttribute('rows', this.config.rows);
            fieldInput.classList.add('bordered');

            this.value = value;
            fieldInput.value = value;

            if (config.focus) {
                fieldInput.focus();
            }

            this.eventListenerFieldInputChange = () => this.receiveUserInputOnChange();
            fieldInput.addEventListener(
                'change',
                this.eventListenerFieldInputChange
            );

            if (this.config.hasOwnProperty('validateOnInput')) {
                this.eventListenerFieldInputInput = () => this.receiveUserInputOnInput();
                fieldInput.addEventListener(
                    'input',
                    this.eventListenerFieldInputInput
                );
            }
        }
    }

    // ---------------------------------------------------------------------

    submitForm(e) {
        this.receiveUserInputOnChange();

        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayout:submit:request'
        );

        e.preventDefault();
    }

    receiveUserInputOnChange() {
        const fieldInput = this.shadowRoot.querySelector('textarea');

        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:userInput:request',
            {
                rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                fieldInfo:  this.config.fieldInfo,
                fieldId:    this.config.fieldId,
                fieldValue: fieldInput.value
            }
        );
    }

    receiveUserInputOnInput() {
        const fieldInput = this.shadowRoot.querySelector('textarea');

        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:validateOnInput:request',
            {
                col: {
                    config:      this.config,
                    elementType: 'ca-form-field-textarea'
                },
                rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                type:       'validateOnInput',
                fieldValue: fieldInput.value
            }
        );
        
    }

    receiveCaFormLayoutChildValidateOnInputResponse({
        rowIdx         = 0,
        colIdx         = 0,
        validateResult = {}
    }) {
        if (
            rowIdx === parseInt(this.getAttribute('row-idx'), 10) &&
            colIdx === parseInt(this.getAttribute('col-idx'), 10)
        ) {
            const fieldInput = this.shadowRoot.querySelector('textarea');

            this.validationMessages = validateResult.colMessages;

            if (this.validationMessages.length === 0) {
                fieldInput.classList.remove('has-validation-messages');
            } else {
                fieldInput.classList.add('has-validation-messages');
            }

            this.caFormFieldValidationEventAggregator.publish(
                'CaFormFieldValidationMessages:update:request',
                {
                    validationMessages: this.validationMessages
                }
            );
        }
    }
}

customElements.define('ca-form-field-textarea', CaFormFieldTextarea);

export {CaFormFieldTextarea};

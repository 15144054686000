/**
 * Stellt eine Darstellungsmöglichkeit für Benachrichtigungen zur Verfügung.
 */
class CaUiLoadingIndicator extends HTMLElement {

    constructor() {
        super();

        this.config        = {};
        this.subscriptions = [];

        if (this.parentNode.host) {
            this.host = this.parentNode.host;
        } else {
            this.host = this.parentNode.parentNode.host;
        }

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }
            :host {
                font-family: var(--ca-font-family);
            }
            .ca-ui-loading-indicator {
                z-index:          4;
                position:         absolute;
                left:             0;
                top:              0;
                right:            0;
                bottom:           0;
                display:          flex;
                flex:             1;
                flex-direction:   row;
                align-items:      center;
                justify-content:  center;
                background-color: var(--ca-ui-loading-indicator-panel-bg);
            }

            .loader,
            .loader:after {
                border-radius: 50%;
                width:         var(--ca-ui-loading-indicator-size);
                height:        var(--ca-ui-loading-indicator-size);
            }
            .loader {
                font-size:         10px;
                position:          relative;
                text-indent:       -9999em;
                border-top:        var(--ca-ui-loading-indicator-border-width) solid var(--ca-ui-loading-indicator-color-bg);
                border-right:      var(--ca-ui-loading-indicator-border-width) solid var(--ca-ui-loading-indicator-color-bg);
                border-bottom:     var(--ca-ui-loading-indicator-border-width) solid var(--ca-ui-loading-indicator-color-bg);
                border-left:       var(--ca-ui-loading-indicator-border-width) solid var(--ca-ui-loading-indicator-color-fg);
                -webkit-transform: translateZ(0);
                -ms-transform:     translateZ(0);
                transform:         translateZ(0);
                -webkit-animation: load8 1.1s infinite linear;
                animation:         load8 1.1s infinite linear;
            }

            .loader-label {
                margin-left: 8px;
            }

            @keyframes load8 {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform:         rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform:         rotate(360deg);
                }
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div class="ca-ui-loading-indicator" tabindex="-1">
                <div
                    class       = "ca-focus-trapstart"
                    tabindex    = "-1"
                    aria-hidden = "true"
                ></div>

                <div class="loader" tabindex="2"></div>
                <div class="loader-label" tabindex="-1"></div>

                <div
                    class       = "ca-focus-trapend"
                    tabindex    = "-1"
                    aria-hidden = "true"
                ></div>
                <div
                    tabindex    = "2"
                    class       = "ca-focus-handle-trap-end"
                    aria-hidden = "true"
                ></div>
            </div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const caFocusStartElem = this.shadowRoot.querySelector('.loader');
        this.eventListenerFocusStartElemFocus = () => this.focusHandleStart();
        caFocusStartElem.addEventListener(
            'focus',
            this.eventListenerFocusStartElemFocus
        );
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-handle-trap-end');
        this.eventListenerFocusEndElemFocus = () => this.focusHandleEnd();
        caFocusEndElem.addEventListener(
            'focus',
            this.eventListenerFocusEndElemFocus
        );
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.host.caUiLoadingIndicatorEventAggregator.subscribe(
                'CaUiLoadingIndicator.config.set:response',
                (params) => this.receiveCaUiLoadingIndicatorConfigSetResponse(params)
            )
        );
        this.subscriptions.push(
            this.host.caUiLoadingIndicatorEventAggregator.subscribe(
                'CaUiLoadingIndicator.setLabel.request',
                (params) => this.answerCaUiLoadingIndicatorSetLabelRequest(params)
            )
        );
        this.shadowRoot.querySelector('.loader').focus();
        this.host.caUiLoadingIndicatorEventAggregator.publish('CaUiLoadingIndicator.config.set:request');
    }

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }

        const caFocusStartElem = this.shadowRoot.querySelector('.loader');
        caFocusStartElem.removeEventListener(
            'focus',
            this.eventListenerFocusStartElemFocus
        );
        
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-trapend');
        caFocusEndElem.removeEventListener(
            'focus',
            this.eventListenerFocusEndElemFocus
        );
    }

    // ---------------------------------------------------------------------

    focusHandleStart() {
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-trapend');

        if (caFocusEndElem) {
            caFocusEndElem.focus();
        }
    }

    focusHandleEnd() {
        const caFocusStartElem = this.shadowRoot.querySelector('.loader');

        if (caFocusStartElem) {
            caFocusStartElem.focus();
        }
    }

    // ---------------------------------------------------------------------

    answerCaUiLoadingIndicatorSetLabelRequest({
        label = ''
    }) {
        const labelElem = this.shadowRoot.querySelector('.loader-label');

        labelElem.innerHTML = label;
    }

    receiveCaUiLoadingIndicatorConfigSetResponse({
        config = {}
    }) {
        if (config.top) {
            const indicatorElem = this.shadowRoot.querySelector('.loader');
            indicatorElem.style.top = config.top;

            const labelElem = this.shadowRoot.querySelector('.loader-label');
            labelElem.style.top = config.top;
        }
    }
}

customElements.define('ca-ui-loading-indicator', CaUiLoadingIndicator);

export {CaUiLoadingIndicator};

import {CaAppEventAggregators} from './ca-app/ca-app-event-aggregators';
import {CaAppConfig}           from './ca-app/ca-app-config';

// Importe für systemweit verfügbare HTML-Elemente (CustomHtmlElements)
import {AnmeldungPage}          from './ca-app-pages/anmeldung-page';
import {RezeptePage}            from './ca-app-pages/rezepte-page';
import {RezeptPage}             from './ca-app-pages/rezept-page';
import {RezeptEditPage}         from './ca-app-pages/rezept-edit-page';
import {RezeptCreatePage}       from './ca-app-pages/rezept-create-page';
import {RezeptDeletePage}       from './ca-app-pages/rezept-delete-page';
import {CaAppUiFilterArt}       from './ca-app-ui/ca-app-ui-filter-art';
import {CaAppUiFilterKategorie} from './ca-app-ui/ca-app-ui-filter-kategorie';

import {CaFormButtonCloudinaryUpload}  from './ca-ui/form/form-layout/components/ca-form-button-cloudinary-upload';
import {CaFormButtonList}              from './ca-ui/form/form-layout/components/ca-form-button-list';
import {CaFormButtonSubmit}            from './ca-ui/form/form-layout/components/ca-form-button-submit';
import {CaFormFieldCheckbox}           from './ca-ui/form/form-layout/components/ca-form-field-checkbox';
import {CaFormFieldInput}              from './ca-ui/form/form-layout/components/ca-form-field-input';
import {CaFormFieldSelect}             from './ca-ui/form/form-layout/components/ca-form-field-select';
import {CaFormFieldTextarea}           from './ca-ui/form/form-layout/components/ca-form-field-textarea';
import {CaFormFieldValidationMessages} from './ca-ui/form/form-layout/mixins/ca-form-field-validation-messages';
import {CaFormLayout}                  from './ca-ui/form/form-layout/ca-form-layout';
import {CaUiGlobalStageController}     from './ca-ui/stage-controller/ca-ui-global-stage-controller';
import {CaUiFatalError}                from './ca-ui/fatal-error/ca-ui-fatal-error';
import {CaUiLoadingIndicator}          from './ca-ui/loading-indicator/ca-ui-loading-indicator';
import {CaUiNotification}              from './ca-ui/notification/ca-ui-notification';
import {CaUiSidebar}                   from './ca-ui/sidebar/ca-ui-sidebar';
import {CaUiToolbar}                   from './ca-ui/toolbar/ca-ui-toolbar';
import { CaToolQuery } from './ca-tools/ca-tool-query';

customElements.define('ca-app',
    class extends HTMLElement {
        constructor() {
            super();
            CaAppConfig.setBaseUrl();

            this.registerServiceWorker();
            this.attachShadow({mode: 'open'});

            this.subscriptions          = [];
            this.toolbarEventAggregator = CaAppEventAggregators.eventAggregatorToolbar;

            this.subscriptions.push(
                this.toolbarEventAggregator.subscribe(
                    'toolbar.config.set:request',
                    () => this.answerToolbarSendConfigSetRequest()
                )
            );
            this.subscriptions.push(
                CaAppEventAggregators.eventAggregatorStage.subscribe(
                    'CaStageController:ready:request',
                    (params) => this.answerCaStageControllerReadyRequest(params)
                )
            );
            this.subscriptions.push(
                CaAppEventAggregators.eventAggregatorApp.subscribe(
                    'CaApp:toolbar:remove:request',
                    (params) => this.answerCaAppToolbarRemoveRequest(params)
                )
            );

            this.setStyle();
            this.setTemplate();

            window.onpopstate = () => {
                CaAppEventAggregators.eventAggregatorStage.publish(
                    'CaStageController:gotoStage:request',
                    {
                        id: window.location.hash.replace('#/', '')
                    }
                );
            }
        }

        registerServiceWorker() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register(
                    './sw_cache_dev.js',
                    {
                        scope: CaAppConfig.getBaseUrl()
                    }
                ).then(
                    (registration) => {
                        const listenForWaitingServiceWorker = (reg, callback) => {
                            const awaitStateChange = () => {
                                reg.installing.addEventListener('statechange', function() {
                                    if (this.state === 'installed') callback(reg);
                                });
                            }

                            if (!reg) return;
                            if (reg.waiting) return callback(reg);
                            if (reg.installing) awaitStateChange();

                            reg.addEventListener('updatefound', awaitStateChange);
                        };

                        // reload once when the new Service Worker starts activating
                        let refreshing = false;
                        navigator.serviceWorker.addEventListener('controllerchange',
                            () => {
                                if (refreshing) return;
                                refreshing = true;
                                location.href = location.href;
                            }
                        );
                        const promptUserToRefresh = (reg) => {
                            reg.waiting.postMessage('skipWaiting');
                        }

                        listenForWaitingServiceWorker(registration, promptUserToRefresh);

                        registration.update();
                    }, (err) => {
                        console.log('Cache ServiceWorker registration failed: ', err);
                    }
                );
            }
        }

        setStyle() {
            let style = document.createElement('style');

            style.textContent = `
                :host {
                    display:        flex;
                    flex:           1;
                    flex-direction: column;
                }
            `;

            this.shadowRoot.appendChild(style);
        }

        setTemplate() {
            const tmpl = document.createElement('template');
            tmpl.innerHTML = `
                <ca-ui-toolbar></ca-ui-toolbar>
                <ca-ui-global-stage-controller></ca-ui-global-stage-controller>
            `;

            this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
        }

        // ---------------------------------------------------------------------

        disconnectedCallback() {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }

        // ---------------------------------------------------------------------

        /**
         * Hier wird die erste Seite der Applikation aufgerufen.
         */
        async answerCaStageControllerReadyRequest() {
            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:addStage:request',
                {
                    id:           'rezepte',
                    stageElement: 'rezepte-page'
                }
            );
            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:addStage:request',
                {
                    id:           'rezept',
                    stageElement: 'rezept-page'
                }
            );
            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:gotoStage:request',
                {
                    id: 'rezepte'
                }
            );
        }

        answerCaAppToolbarRemoveRequest() {
            this.shadowRoot.children[1].addEventListener('animationend', () => {
                this.shadowRoot.removeChild(
                    this.shadowRoot.children[1]
                );
                this.shadowRoot.children[1].style.top = 0;
            }, {
                once: true
            });
            this.shadowRoot.children[1].classList.add('remove');
            this.shadowRoot.children[2].classList.add('no-toolbar-top');
        }

        answerToolbarSendConfigSetRequest() {
            this.toolbarEventAggregator.publish(
                'toolbar.config.set:response',
                {
                    sectionCenter: [
                        /*{
                            type:  'image',
                            id:    '__logo',
                            src:   'conaktiv_logo_dark.png',
                            title: 'ConAktiv AZE',
                            width: 140
                        },*/
                        {
                            id:         '__status',
                            type:       'text',
                            label:      'Rezepte',
                            fontWeight: 500
                        }
                    ]
                }
            );
        }
    }
);

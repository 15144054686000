import {CaToolEventAggregator} from '../ca-tools/ca-tool-event-aggregator';

/**
 * Stellt systemweit zugängliche EventAggregatoren zur Verfügung
 */
const CaAppEventAggregators = {
    eventAggregatorApp:     new CaToolEventAggregator(),
    eventAggregatorStage:   new CaToolEventAggregator(),
    eventAggregatorToolbar: new CaToolEventAggregator()
}

export {CaAppEventAggregators};

import {CaStageController}     from './ca-stage-controller';
import {CaAppEventAggregators} from '../../ca-app/ca-app-event-aggregators.js';

class CaUiGlobalStageController extends CaStageController {

    constructor() {
        super();

        this.subscriptions.push(
            CaAppEventAggregators.eventAggregatorStage.subscribe(
                'CaStageController:addStage:request',
                (params) => this.answerCaStageControllerAddStageRequest(params)
            )
        );
        this.subscriptions.push(
            CaAppEventAggregators.eventAggregatorStage.subscribe(
                'CaStageController:gotoStage:request',
                (params) => this.answerCaStageControllerGotoStageRequest(params)
            )
        );
        this.subscriptions.push(
            CaAppEventAggregators.eventAggregatorStage.subscribe(
                'CaStageController:removeStage:request',
                (params) => this.answerCaStageControllerRemoveStageRequest(params)
            )
        );
        this.subscriptions.push(
            CaAppEventAggregators.eventAggregatorStage.subscribe(
                'CaStageController:showFatalError:request',
                (params) => this.answerCaStageControllerShowFatalErrorRequest(params)
            )
        );
    }

    setStyle() {
        super.setStyle();

        this.shadowRoot.firstChild.innerHTML += `
            :host {
                font-family: var(--ca-font-family);
                position:    absolute;
                left:        0;
                top:         44px;
                right:       0;
                bottom:      0;
                overflow:    hidden;
            }
            :host(.no-toolbar-top) {
                animation-duration:        0.5s;
                animation-name:            slideOutToTop;
                animation-iteration-count: 1;
            }
            @media print{
                :host {
                    top: 0;
                }
            }
        `;
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        CaAppEventAggregators.eventAggregatorStage.publish('CaStageController:ready:request');
    }

    // ---------------------------------------------------------------------
}

customElements.define('ca-ui-global-stage-controller', CaUiGlobalStageController);

export {CaUiGlobalStageController};

customElements.define('ca-ui-notification',
    /**
     * Stellt eine Darstellungsmöglichkeit für Benachrichtigungen zur Verfügung.
     */
    class extends HTMLElement {

        constructor() {
            super();

            this.caNotificationsElem = undefined;
            this.config              = {};
            this.notifications       = [];
            this.subscriptions       = [];

            this.attachShadow({mode: 'open'});
            this.setStyle();
            this.setTemplate();
        }

        setStyle() {
            let style = document.createElement('style');

            style.textContent = `
                :host {
                    font-family:     var(--ca-font-family);
                    display:         flex;
                    flex:            initial;
                    flex-direction:  row;
                    justify-content: center;
                }
                .ca-notifications {
                    display:        flex;
                    flex:           1;
                    flex-direction: row;
                },
                .ca-notifications.left {
                    margin-left:  0;
                    margin-right: auto;
                }
                .ca-notifications.center {
                    margin-left:  auto;
                    margin-right: auto;
                }
                .ca-notifications.right {
                    margin-left:  auto;
                    margin-right: 0;
                }
                .ca-notification {
                    display:        flex;
                    flex:           1;
                    flex-direction: column;
                    align-items:    stretch;
                    padding:        8px;
                    border:         1px var(--ca-ui-notification-border-color) solid;
                    border-radius:  var(--ca-ui-notification-border-radius);
                }
                .ca-notification-content {
                    flex:           1;
                    display:        flex;
                    flex-direction: row;
                    align-items:    center;
                }
                .ca-notification-content h3 {
                    font-weight: 500;
                    margin:      0 0 8px 0;
                }
                .icon {
                    flex:          initial;
                    font-size:     var(--ca-font-size--notification-icon);
                    line-height:   100%;
                    padding-left:  8px;
                    padding-right: 14px;
                }
                .ca-notification-actions {
                    display:        flex;
                    flex-direction: column;
                    border-top:     1px #ddd dashed;
                    padding-top:    8px;
                    margin-top:     10px;
                }
                .ca-notification-actions a {
                    text-decoration:  none;
                    color:            var(--ca-ui-notification-action-color--fg);
                    background-color: var(--ca-ui-notification-action-color--bg);
                    padding:          8px;
                    border-radius:    var(--ca-ui-notification-action-border-radius);
                }
                .ca-notification-actions a:hover {
                    color:            var(--ca-ui-notification-action-color--fg-hover);
                    background-color: var(--ca-ui-notification-action-color--bg-hover);
                }

                [class^="ca-icon"] {
                    /* use !important to prevent issues with browser extensions that change fonts */
                    font-family: 'icomoon' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;

                    /* Better Font Rendering =========== */
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                .ca-icon-warning:before {
                    content: "\\e913";
                }
                .ca-icon-info:before {
                    content: "\\e601";
                }
            `;

            this.shadowRoot.appendChild(style);
        }

        setTemplate() {
            const tmpl = document.createElement('template');
            tmpl.innerHTML = `<div class="ca-notifications"></div>`;

            this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

            this.caNotificationsElem = this.shadowRoot.querySelector('.ca-notifications');
        }

        // ---------------------------------------------------------------------

        connectedCallback() {
            let parentNode = this.parentNode;

            while (!parentNode.host) {
                parentNode = parentNode.parentNode;
            }

            this.host = parentNode.host;

            this.subscriptions.push(
                this.host.caUiNotificationEventAggregator.subscribe(
                    'CaUiNotification.config.set:response',
                    (params) => this.receiveNotificationConfigSetResponse(params)
                )
            );
            this.host.caUiNotificationEventAggregator.publish('CaUiNotification.config.set:request');
        }

        disconnectedCallback() {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }

            let idx = 0;
            for (const notification of this.notifications) {
                if (notification.config?.allowClose) {
                    const closeBtn = this.caNotificationsElem.children[idx].querySelector('a[action-type="close"]')
                    closeBtn.removeEventListener(
                        'click',
                        this.eventListenerCloseBtnClick
                    );
                }

                idx++;
            }
        }

        renderNotifications() {
            this.caNotificationsElem.innerHTML = '';

            for (const notification of this.notifications) {
                let allowCloseStr = '';
                let titleStr      = '';

                if (notification.title) {
                    titleStr = `
                        <h3>${notification.title}</h3>
                    `;
                }
                if (notification.config?.allowClose) {
                    allowCloseStr = `
                        <div class="ca-notification-actions">
                            <a href="#" action-type="close">Weiter</a>
                        </div>
                    `;
                }

                const tmpl = document.createElement('template');
                tmpl.innerHTML = `
                    <div class="ca-notification" style="${notification.colorFg ? 'color: ' + notification.colorFg + ';' : ''}">
                        <div class="ca-notification-content">
                            <div class="icon">${notification.type === 'info' ? '<i class="ca-icon-info"></i>' : '<i class="ca-icon-warning"></i>'}</div>
                            <div>
                                ${titleStr}
                                <div class="label">${notification.text}</div>
                            </div>
                        </div>
                        ${allowCloseStr}
                    </div>
                `;

                this.caNotificationsElem.appendChild(tmpl.content.cloneNode(true));

                if (notification.config?.allowClose) {
                    const notifications = this.caNotificationsElem.querySelectorAll('.ca-notification');
                    const closeBtn      = notifications[notifications.length - 1].querySelector('a[action-type="close"]')

                    this.eventListenerCloseBtnClick = (event) => this.handleCloseButton(event);
                    closeBtn.addEventListener(
                        'click',
                        this.eventListenerCloseBtnClick
                    );
                }
            }
        }

        // ---------------------------------------------------------------------

        handleCloseButton(event) {
            this.host.caUiNotificationEventAggregator.publish(
                'CaUiNotification.action.close:request'
            );
            event.preventDefault();
        }

        // ---------------------------------------------------------------------

        receiveNotificationConfigSetResponse({
            config        = {},
            notifications = []
        }) {
            let configCss = '';

            this.config.align     = config?.align || 'left';
            this.config.maxWidth  = config?.maxWidth || 0;   // 0 = Verfügbare Breite einnehmen, ansonsten Breite in Pixel
            this.config.marginTop = config?.marginTop || 0;
            this.config.padding   = config?.padding || '';
            this.config.class     = config?.class || '';

            this.notifications = notifications;

            this.config.maxWidth > 0  ? configCss += 'max-width: ' + this.config.maxWidth + 'px;' : '';
            this.config.marginTop > 0 && notifications.length > 0 ? configCss += 'margin-top: ' + this.config.marginTop + 'px;' : '';
            this.config.padding !== ''  ? configCss += 'padding: ' + this.config.padding + ';' : '';

            if (config.style) {
                this.shadowRoot.firstChild.innerHTML += config.style;
            }

            this.caNotificationsElem.style = configCss;
            this.caNotificationsElem.classList.add(this.config.align);

            if (this.config.class !== '') {
                this.caNotificationsElem.classList.add(this.config.class);
            }

            this.renderNotifications();
        }
    }
);

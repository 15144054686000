import {Cloudinary} from "@cloudinary/url-gen";
import { CaAppConfig } from "../../../../ca-app/ca-app-config";
import { CaToolQuery } from "../../../../ca-tools/ca-tool-query";

class CaFormButtonCloudinaryUpload extends HTMLElement {

    cloudinaryMediaEditor;
    cloudinaryWidget;
    eventListeners;
    value;

    constructor() {
        super();

        this.cloudinaryMediaEditor = cloudinary.mediaEditor();
        this.config                = {};
        this.eventListeners        = [];
        this.host                  = this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host;
        this.subscriptions         = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }
            :host {
                font-family:    var(--ca-font-family);
                display:        flex;
                flex:           1;
                flex-direction: column;
            }
            .ca-form-button-submit {
                display:       flex;
                flex:          1;
                margin-bottom: 17px;
            }
            .ca-form-button-submit a {
                display:          flex;
                flex:             1;
                flex-direction:   column;
                align-items:      center;
                justify-content:  center;
                min-height:       40px;
                font-size:        var(--ca-font-size--medium);
                font-weight:      500;
                text-decoration:  none;
                color:            var(--ca-ui-form-button-submit--fg);
                background-color: var(--ca-ui-form-button-submit--bg);
                border-width:     2px;
                border-color:     var(--ca-ui-form-button-submit--border-color);
                border-style:     solid;
                border-radius:    5px;
                box-shadow:       rgba(0,0,0,0.15) 0 0 10px;
                transition:       box-shadow 0.2s;
            }
            .ca-form-button-submit a:hover {
                color:            var(--ca-ui-form-button-submit--fg-hover);
                background-color: var(--ca-ui-form-button-submit--bg-hover);
                border-color:     var(--ca-ui-form-button-submit--border-color-hover);
                box-shadow:       rgba(0,0,0,0.4) 0 0 12px;
            }
            .ca-form-button-submit a:focus {
                color:            var(--ca-ui-form-button-submit--fg-focus);
                background-color: var(--ca-ui-form-button-submit--bg-focus);
                border-color:     var(--ca-ui-form-button-submit--border-color-focus);
                box-shadow:       rgba(0,0,0,0.5) 0 0 12px;
            }
            .images {
                display:        flex;
                flex-direction: row;
                flex-wrap:      wrap;
            }
            .image {
                display:        flex;
                flex-direction: column;
                border:         1px #333 solid;
                border-radius:  4px;
                margin:         0 8px 8px 0;
            }
            .image img {
                border-top-left-radius:  4px;
                border-top-right-radius: 4px;
            }
            .toolbar {
                display:        flex;
                flex:           1;
                flex-direction: row;
            }
            .toolbar a {
                display:         flex;
                flex:            1;
                align-items:     center;
                justify-content: center;
                text-decoration: none;
                color:           #333;
                min-height:      30px;
            }
            .toolbar a:first-child {
                border-right: 1px #333 solid;
            }
            .toolbar a:hover {
                background-color: #f2f2f2;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        this.cloudinaryWidget = cloudinary.createUploadWidget(
            {
                cloudName:    CaAppConfig.cloudinary.cloudName,
                uploadPreset: CaAppConfig.cloudinary.uploadPreset,
                sources: [
                    "local",
                    "url",
                    "camera"
                ],
                cropping: true,
                multiple: false,
                defaultSource: "local",
            }, (error, result) => this.onImageUpload(error, result)
        );

        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div
                class = "ca-form-button-submit"
            >
                <a
                    href     = "#"
                    tabindex = "2"
                >
                    <span></span>
                </a>
            </div>

            <div class = "images"></div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const fieldInput = this.shadowRoot.querySelector('a');
        this.eventListenerFieldInputClick = (e) => this.receiveUserInput(e);
        fieldInput.addEventListener(
            'click',
            this.eventListenerFieldInputClick
        );
        this.eventListenerFieldInputKeydown = (e) => this.onKeyDown(e);
        fieldInput.addEventListener(
            'keydown',
            this.eventListenerFieldInputKeydown
        );
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:attached:response',
                (params) => this.receiveCaFormLayoutChildAttachedResponse(params)
            )
        );
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:updateRecord:request',
                (params) => this.receiveCaFormLayoutUpdateRecordRequest(params)
            )
        );
        this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:attached:request',
            {
                elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                elColIdx: parseInt(this.getAttribute('col-idx'), 10)
            }
        );
    }

    disconnectedCallback() {
        const fieldInput = this.shadowRoot.querySelector('a');
        fieldInput.removeEventListener(
            'click',
            this.eventListenerFieldInputClick
        );
        fieldInput.removeEventListener(
            'keydown',
            this.eventListenerFieldInputKeydown
        );
    }

    // ---------------------------------------------------------------------

    async onButtonPress(link, event) {
        event.preventDefault();

        const action        = link.getAttribute('data-action');
        const imagePublicId = link.getAttribute('data-public-id');

        if (action === 'edit') {
            this.cloudinaryMediaEditor.update({
                cloudName: CaAppConfig.cloudinary.cloudName,
                publicIds: [imagePublicId],
            });

            this.cloudinaryMediaEditor.on(
                'export',
                (data) =>  {
                    console.log('data:', data)
                }
            );

            this.cloudinaryMediaEditor.show();
        } else if (action === 'delete') {
            const images = this.value.split(';;');
            let imageToDelete = '';

            for (const image of images) {
                if (image.indexOf(imagePublicId) > -1) {
                    imageToDelete = image;
                    break;
                }
            }

            if (imageToDelete !== '') {
                this.value = this.value.replace(`${imageToDelete};;`, '');
            }

            this.host.caFormLayoutEventAggregator.publish(
                'CaFormLayoutChild:userInput:request',
                {
                    rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                    colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                    fieldId:    this.config.fieldId,
                    fieldValue: this.value
                }
            );

            this.renderImages();
        }
    }

    onImageUpload(error, result) {
        if (!error && result && result.event === "success") {
            this.value += `${result.info.path}||${result.info.public_id};;`;

            this.host.caFormLayoutEventAggregator.publish(
                'CaFormLayoutChild:userInput:request',
                {
                    rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                    colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                    fieldId:    this.config.fieldId,
                    fieldValue: this.value
                }
            );

            this.renderImages();
        }
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.receiveUserInput(e);
        }
    }

    receiveCaFormLayoutChildAttachedResponse({
        rowIdx = 0,
        colIdx = 0,
        config = {},
        value  = '',
    }) {
        if (
            rowIdx === parseInt(this.getAttribute('row-idx'), 10) &&
            colIdx === parseInt(this.getAttribute('col-idx'), 10)
        ) {
            this.value          = value;
            this.config.fieldId = config?.fieldId || '';
            this.config.align   = config?.align   || 'stretch';
            this.config.title   = config?.title   || '';
            this.config.class   = config?.class   || '';

            if (config.style) {
                this.shadowRoot.firstChild.innerHTML += config.style;
            }

            const buttonTitleElem = this.shadowRoot.querySelector('span');
            buttonTitleElem.innerHTML = this.config.title;

            const buttonElem = this.shadowRoot.querySelector('.ca-form-button-submit');

            if (this.config.class !== '') {
                buttonElem.classList.add(this.config.class);
            }
            if (config.focus) {
                buttonElem.querySelector('a').focus();
            }

            this.renderImages();

            this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host.caFormLayoutEventAggregator.publish(
                'CaFormLayoutChild:rendered:request',
                {
                    elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                    elColIdx: parseInt(this.getAttribute('col-idx'), 10)
                }
            );
        }
    }

    receiveCaFormLayoutUpdateRecordRequest({
        record  = {}
    }) {
        if (record.hasOwnProperty(this.config.fieldId)) {
            this.value = record[this.config.fieldId];

            this.renderImages();
        }
    }

    renderImages() {
        const imageContainer = this.shadowRoot.querySelector('.images');
        const images         = this.value.split(';;');

        // Alte Eventlistener entfernen
        let imageSections   = imageContainer.querySelectorAll('.image');
        let imageSectionIdx = 0;

        imageSections.forEach(
            (imageSection) => {
                const links = imageSection.querySelectorAll('.toolbar a');
                let linkIdx = 0;

                links.forEach(
                    (link) => {
                        link.removeEventListener(
                            'click',
                            this.eventListeners[imageSectionIdx][linkIdx]
                        );

                        linkIdx++;
                    }
                );

                imageSectionIdx++;
            }
        );

        this.eventListeners = [];
        imageContainer.innerHTML = '';

        for (const imageDef of images) {
            if (imageDef.indexOf('||') > -1) {
                const [imagePath, imagePublicId] = imageDef.split('||');

                const tmpl = document.createElement('template');
                tmpl.innerHTML = `
                    <div class="image">
                        <img src="https://res.cloudinary.com/${CaAppConfig.cloudinary.cloudName}/image/upload/c_fit,h_200/${imagePath}">
                        <div class="toolbar">
                            <a
                                href           = "#"
                                data-public-id = "${imagePublicId}"
                                data-action    = "delete"
                            >
                                Löschen
                            </a>
                            <a
                                href           = "#"
                                data-public-id = "${imagePublicId}"
                                data-action    = "edit"
                            >
                                Bearbeiten
                            </a>
                        </div>
                    </div>
                `;

                imageContainer.appendChild(tmpl.content.cloneNode(true));
            }
        }

        imageSections   = imageContainer.querySelectorAll('.image');
        imageSectionIdx = 0;

        imageSections.forEach(
            (imageSection) => {
                const links = imageSection.querySelectorAll('.toolbar a');

                links.forEach(
                    (link) => {
                        const eventListener = (event) => this.onButtonPress(link, event);

                        link.addEventListener(
                            'click',
                            eventListener
                        );

                        if (!this.eventListeners[imageSectionIdx]) {
                            this.eventListeners[imageSectionIdx] = [];
                        }
                        this.eventListeners[imageSectionIdx].push(eventListener);
                    }
                );

                imageSectionIdx++;
            }
        );
    }

    // ---------------------------------------------------------------------

    receiveUserInput(e) {
        if (this.config.fieldId) {
            this.cloudinaryWidget.open();
        }

        e.preventDefault();
    }
}

customElements.define('ca-form-button-cloudinary-upload', CaFormButtonCloudinaryUpload);

export {CaFormButtonCloudinaryUpload};

/**
 * Stellt eine Seitenleiste zur Verfügung.
 */
class CaUiSidebar extends HTMLElement {

    config;
    subscriptions;
    
    constructor() {
        super();

        this.config = {
            sidebarId: this.getAttribute('sidebar-id'),
            position:  'left'
        };
        this.subscriptions = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                position:         absolute;
                background-color: var(--ca-ui-sidebar-color--bg);
                width:            var(--ca-ui-sidebar-width);
                top:              0;
                bottom:           0;
                overflow-y:       auto;
            }
            :host(.left) {
                left:         0;
                border-right: 1px var(--ca-ui-sidebar-color--border) solid;
            }
            :host(.right) {
                right:       0;
                border-left: 1px var(--ca-ui-sidebar-color--border) solid;
            }
            :host(.right.hidden) {
                right: calc(-1 * var(--ca-ui-sidebar-width-with-border));
            }
            :host(.right.modal),
            :host(.right.hidden.modal) {
                z-index:    1;
                right:      0;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            }

            :host(.animate-slidein-from-right) {
                animation-duration:        0.5s;
                animation-name:            slideinfromright;
                animation-iteration-count: 1;
            }
            :host(.animate-slideout-to-right) {
                animation-duration:        0.5s;
                animation-name:            slideouttoright;
                animation-iteration-count: 1;
            }

            @keyframes slideOutToTop {
                from {
                    transform: translateY(0);
                }

                to {
                    transform: translateY(-44px);
                }
            }
            @keyframes slideinfromright {
                from {
                    transform: translate(100%);
                }

                to {
                    transform: translate(0);
                }
            }
            @keyframes slideouttoright {
                from {
                    transform: translate(0);
                }

                to {
                    transform: translate(100%);
                }
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = ``;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.parentNode.host.caUiSidebarEventAggregator.subscribe(
                'CaUiSidebar.config.set:response',
                (params) => this.receiveCaUiSidebarConfigSetResponse(params)
            )
        );
        this.subscriptions.push(
            this.parentNode.host.caUiSidebarEventAggregator.subscribe(
                'CaUiSidebar.send.sidebarButton:request',
                (params) => this.answerCaUiSidebarSendSidebarButtonRequest(params)
            )
        );

        this.parentNode.host.caUiSidebarEventAggregator.publish('CaUiSidebar.config.set:request');
    }

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    // ---------------------------------------------------------------------

    answerCaUiSidebarSendSidebarButtonRequest() {
        const buttonDef = {
            type:       'button',
            id:         '__sidebar',
            label:      '',
            icon:       'ca-icon-menu',
            conditions: [
                {
                    property: 'sizePoint',
                    operator: '<',
                    value:    20
                }
            ],
            callback:   () => {
                const sidebarElem = this.shadowRoot.host;

                if (sidebarElem.classList.contains('modal')) {
                    sidebarElem.addEventListener('animationend', () => {
                        sidebarElem.classList.remove('animate-slideout-to-right');
                        sidebarElem.classList.remove('modal');
                    }, {
                        once: true
                    });
                    sidebarElem.classList.add('animate-slideout-to-right');
                } else {
                    sidebarElem.classList.add('modal');
                    sidebarElem.addEventListener('animationend', () => {
                        sidebarElem.classList.remove('animate-slidein-from-right');
                    }, {
                        once: true
                    });
                    sidebarElem.classList.add('animate-slidein-from-right');
                }
            }
        };

        return buttonDef;
    }

    /**
     * Empfängt die Konfiguration.
     * 
     * @param {object}           options          - Optionen
     * @param {'left' | 'right'} options.position - Position
     * @param {Object[]}         options.items    - Einträge
     * @param {string}           options.items[].itemId  - ID des Eintrags
     * @param {string}           options.items[].element - customElement Kennzeichner
     */
    receiveCaUiSidebarConfigSetResponse({
        position = 'left',
        items    = []
    }) {
        this.config.position = position;
        this.config.items    = items;
        
        this.shadowRoot.host.classList.add(position);

        for (const item of this.config.items) {
            const tmpl = document.createElement('template');
            tmpl.innerHTML = `<${item.element}></${item.element}>`;

            this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
        }
    }
}

customElements.define('ca-ui-sidebar', CaUiSidebar);

export {CaUiSidebar};

import {CaToolQuery} from '../ca-tools/ca-tool-query';

/**
 * Verwaltet den Loginstatus des Benutzers.
 */
const CaAppLoginState = {

    isLoggedIn: false,

    getLocalUserHash() {
        return localStorage.getItem('hash');
    },

    setLocalUserHash(hash) {
        localStorage.setItem('hash', hash);
    }
    
};

export {CaAppLoginState};

const CaTools = {

    storageTypes: {},

    /**
     * Kopiert ein Objekt.
     *
     * Ist ein Wert im Objekt selbst wieder ein Objekt, wird dafür nur eine
     * Referenz erstellt.
     * Benchmarks siehe "http://jsben.ch/#/bWfk9".
     */
    copyObjectShallow(object) {
        return Object.assign({}, object);
    },

    /**
     * Kopiert ein Objekt mit JSON-Einschränkungen.
     *
     * Datumwerte werden z.B. in Strings konvertiert.
     * Benchmarks siehe "http://jsben.ch/#/bWfk9".
     */
    copyObjectDeep(object) {
        return JSON.parse(JSON.stringify(object));
    },

    isEmptyObject(object) {
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }

        return true;
    },

    isStorageAvailable(type) {
        if (this.storageTypes.hasOwnProperty(type) === false) {
            try {
                let storage = window[type],
                    x = '__storage_test__';

                storage.setItem(x, x);
                storage.removeItem(x);

                this.storageTypes[type] = true;
            }
            catch(e) {
                this.storageTypes[type] = false;
            }
        }

        return this.storageTypes[type];
    },

    leftPad(str, padWith, padLength) {
        let paddedStr = '';

        for (let padIdx = 0; padIdx < padLength; padIdx++) {
            paddedStr += padWith;
        }

        return paddedStr.substring(0, paddedStr.length - str.length) + str;
    },

    rightPad(str, padWith, padLength) {
        let paddedStr = '';

        for (let padIdx = 0; padIdx < padLength; padIdx++) {
            paddedStr += padWith;
        }

        return str + paddedStr.substring(0, paddedStr.length - str.length);
    },

    /**
     * Registriert Größenänderungen für ein Element.
     * 
     * Enthält einen Debounce-Mechanismus.
     */
    registerResizeObserver({
        resizeEventAggregator = null
    }) {
        const resizeDelay = 150;
        const breakpoints = [
            567,
            768
        ];
        let resizeTimeout = false;

        return new ResizeObserver(
            (entries) => {
                clearTimeout(resizeTimeout);

                resizeTimeout = setTimeout(
                    () => {
                        for (let entry of entries) {
                            let width = 0;

                            if (entry.contentBoxSize) { // Firefox / Chrome
                                width = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
                                width = width.inlineSize;
                            } else if (entry.contentRect) { // Safari
                                width = entry.contentRect.width;
                            }

                            if (resizeEventAggregator) {
                                let sizePoint;

                                if (width < breakpoints[0]) {
                                    sizePoint = 10;
                                } else if (width < breakpoints[1]) {
                                    sizePoint = 20;
                                } else {
                                    sizePoint = 30;
                                }

                                resizeEventAggregator.publish(
                                    'CaTools.resizeObserver.resize:request',
                                    {
                                        sizePoint: sizePoint
                                    }
                                );
                            }
                        }
                    },
                    resizeDelay
                );
            }
        );
    },

    translateScreenSize(screenSize) {
        if (screenSize <= 10) {
            return 'small';
        } else if (screenSize <= 20) {
            return 'medium';
        } else {
            return 'large';
        }
    }

};

export {CaTools};

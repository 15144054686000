class CaFormFieldValidationMessages extends HTMLElement {
    constructor() {
        super();

        this.subscriptions      = [];
        this.validationMessages = [];

        this.subscriptions.push(
            this.parentNode.parentNode.host.caFormFieldValidationEventAggregator.subscribe(
                'CaFormFieldValidationMessages:update:request',
                (params) => this.answerCaFormFieldValidationMessagesUpdateRequest(params)
            )
        );

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();

    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            all:     initial;
            contain: content;
            * {
                outline: none;
            }
            :host {
                font-family: var(--ca-font-family);
                font-size:   var(--ca-font-size--small);
            }
            :host(.has-messages) {
                border:                     1px var(--ca-ui-form-input--border-color) solid;
                border-top:                 none;
                border-bottom-left-radius:  3px;
                border-bottom-right-radius: 3px;
            }
            .ca-validation-message {
                display:          flex;
                flex-direction:   row;
                align-items:      center;
                background-color: rgba(255,255,255,0.85);
                border-top:       1px var(--ca-ui-form-input--border-color) solid;
                padding:          4px 8px;
            }
            .ca-validation-message:first-child {
                border-top: none;
            }
            .ca-validation-message.error {
                color: var(--ca-ui-form-validation-color-error--fg);
            }
            .ca-validation-message.warning {
                color: var(--ca-ui-form-validation-color-warning--fg);
            }
            .ca-validation-message i {
                font-style:   normal;
                font-size:    18px;
                line-height:  100%;
                margin-right: 4px;
            }

            [class^="ca-icon"] {
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            .ca-icon-warning:before {
                content: "\\e913";
            }
            .ca-icon-info:before {
                content: "\\e601";
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = ``;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    answerCaFormFieldValidationMessagesUpdateRequest({
        validationMessages = []
    }) {
        this.validationMessages = validationMessages;

        if (this.validationMessages.length === 0) {
            this.classList.remove('has-messages');
        } else {
            this.classList.add('has-messages');
        }

        const tmpl = document.createElement('template');
        let validationStr = '';

        for (const validationMessage of this.validationMessages) {
            validationStr += `
                <div class="ca-validation-message ${validationMessage.type}">
                    <i>${validationMessage.type === 'warning' ? '<i class="ca-icon-info"></i>' : '<i class="ca-icon-warning"></i>'}</i>
                    <span>${validationMessage.message}</span>
                </div>
            `;
        }

        tmpl.innerHTML = validationStr;

        while (
            this.shadowRoot.lastChild &&
            this.shadowRoot.lastChild.nodeName !== 'STYLE'
        ) {
            this.shadowRoot.removeChild(this.shadowRoot.lastChild);
        }

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }
}

customElements.define('ca-form-field-validation-messages', CaFormFieldValidationMessages);

export {CaFormFieldValidationMessages};

/**
 * Stellt Prüffunktionen für Formularfelder zur Verfügung
 *
 * Mögliche Prüfungen:
 * - notEmpty
 * - noSurroundingWhitespace
 */
class CaFormLayoutValidator {

    constructor() {
        this.layoutDefinition = {};
        this.record           = {};
    }

    setLayoutDefinition({
        layoutDefinition = {}
    }) {
        this.layoutDefinition = layoutDefinition;
    }

    setRecord({
        record = {}
    }) {
        this.record = record;
    }

    validateCol({
        col    = col,
        rowIdx = rowIdx,
        colIdx = colIdx,
        type   = 'validateOnInput',
        record = undefined
    }) {
        if (!record) {
            record = this.record;
        }
        const colMessages = [];

        let allValid                         = true;
        let checkMustEqual                   = false;
        let checkMustEqualType               = 'validateOnInput';
        let checkMustEqualField              = '';
        let checkNotEmpty                    = false;
        let checkNotEmptyType                = 'validateOnInput';
        let checkNoSurroundingWhitespace     = false;
        let checkNoSurroundingWhitespaceType = 'validateOnInput';

        if (type === 'all') {
            if (col.config['validateOnSubmit']?.notEmpty) {
                checkNotEmpty     = true;
                checkNotEmptyType = 'validateOnSubmit';
            } else if (col.config['validateOnInput']?.notEmpty) {
                checkNotEmpty     = true;
                checkNotEmptyType = 'validateOnInput';
            }

            if (col.config['validateOnSubmit']?.noSurroundingWhitespace) {
                checkNoSurroundingWhitespace     = true;
                checkNoSurroundingWhitespaceType = 'validateOnSubmit';
            } else if (col.config['validateOnInput']?.noSurroundingWhitespace) {
                checkNoSurroundingWhitespace     = true;
                checkNoSurroundingWhitespaceType = 'validateOnInput';
            }

            if (col.config['validateOnSubmit']?.mustEqual) {
                checkMustEqual      = true;
                checkMustEqualType  = 'validateOnSubmit';
                checkMustEqualField = col.config['validateOnSubmit'].mustEqual;
            } else if (col.config['validateOnInput']?.mustEqual) {
                checkMustEqual      = true;
                checkMustEqualType  = 'validateOnInput';
                checkMustEqualField = col.config['validateOnInput'].mustEqual;
            }
        } else {
            if (col.config[type]?.notEmpty) {
                checkNotEmpty     = true;
                checkNotEmptyType = type;
            }
            if (col.config[type]?.noSurroundingWhitespace) {
                checkNoSurroundingWhitespace     = true;
                checkNoSurroundingWhitespaceType = type;
            }
            if (col.config[type]?.mustEqual) {
                checkMustEqual      = true;
                checkMustEqualType  = type;
                checkMustEqualField = col.config[type]?.mustEqual;
            }
        }

        if (checkNotEmpty) {
            const validationResult = this.checkNotEmpty({
                fieldValue:  record[col.config.fieldId],
                elementType: col.elementType
            });
            if (validationResult.isValid === false) {
                allValid = checkNotEmptyType === 'validateOnSubmit' ? false : allValid;

                colMessages.push({
                    rowIdx:               rowIdx,
                    colIdx:               colIdx,
                    type:                 checkNotEmptyType === 'validateOnSubmit' ? 'error' : 'warning',
                    validationErrorPoint: 'notEmpty',
                    message:              'Dieses Feld darf nicht leer sein'
                });
            }
        }
        if (checkNoSurroundingWhitespace) {
            const validationResult = this.checkNoSurroundingWhitespace({
                fieldValue:  record[col.config.fieldId],
                elementType: col.elementType
            });
            if (validationResult.isValid === false) {
                allValid = checkNoSurroundingWhitespaceType === 'validateOnSubmit' ? false : allValid;

                colMessages.push({
                    rowIdx:               rowIdx,
                    colIdx:               colIdx,
                    type:                 checkNoSurroundingWhitespaceType === 'validateOnSubmit' ? 'error' : 'warning',
                    validationErrorPoint: 'noSurroundingWhitespace',
                    message:              'Leerzeichen am Anfang und/oder Ende'
                });
            }
        }
        if (checkMustEqual) {
            const validationResult = this.checkMustEqual({
                fieldValue:          record[col.config.fieldId],
                elementType:         col.elementType,
                record:              record,
                checkMustEqualField: checkMustEqualField
            });

            if (validationResult.isValid === false) {
                checkMustEqualType = 'validateOnSubmit';
                allValid = checkMustEqualType === 'validateOnSubmit' ? false : allValid;

                colMessages.push({
                    rowIdx:               rowIdx,
                    colIdx:               colIdx,
                    type:                 checkMustEqualType === 'validateOnSubmit' ? 'error' : 'warning',
                    validationErrorPoint: 'mustEqual',
                    message:              'Dieses Feld muss dem anderen Feld entsprechen'
                });
            }
        }

        return {
            allValid:    allValid,
            colMessages: colMessages
        };
    }

    validate() {
        let colMessages = [];
        let allValid = true;
        let rowIdx   = 0;

        for (const row of this.layoutDefinition.rows) {
            let colIdx = 0;

            for (const col of row.cols) {
                const validateResult = this.validateCol({
                    col:    col,
                    rowIdx: rowIdx,
                    colIdx: colIdx,
                    type:   'all'
                });

                if (validateResult.allValid === false) {
                    allValid = false;
                }
                colMessages = colMessages.concat(validateResult.colMessages);

                colIdx++;
            }
            rowIdx++;
        }

        return {
            allValid:    allValid,
            colMessages: colMessages
        };
    }

    checkNotEmpty({
        fieldValue  = '',
        elementType = ''
    }) {
        let isValid = true;

        if (elementType === 'ca-form-field-input') {
            const checkValue = fieldValue.replace(/[\s]/gi, '');

            if (checkValue === '') {
                isValid = false;
            }
        }

        return {
            isValid: isValid
        };
    }

    checkNoSurroundingWhitespace({
        fieldValue  = '',
        elementType = ''
    }) {
        let isValid = true;

        if (elementType === 'ca-form-field-input') {
            if (
                fieldValue.substring(fieldValue.length - 1) === ' ' ||
                fieldValue.substring(0, 1)                  === ' '
            ) {
                isValid = false;
            }
        }

        return {
            isValid: isValid
        };
    }

    checkMustEqual({
        fieldValue          = '',
        elementType         = '',
        record              = {},
        checkMustEqualField = ''
    }) {
        let isValid = true;

        if (elementType === 'ca-form-field-input') {
            if (fieldValue !== record[checkMustEqualField]) {
                isValid = false;
            }
        }

        return {
            isValid: isValid
        };
    }
}

export {CaFormLayoutValidator};

class CaFormButtonList extends HTMLElement {
    
    constructor() {
        super();

        this.config        = {
            title:   '',
            buttons: []
        };
        this.host          = this.parentNode.parentNode.parentNode.host.parentNode.parentNode.host;
        this.subscriptions = [];

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        const style = document.createElement('style');

        style.textContent = `
            :host {
                font-family:    var(--ca-font-family);
                display:        flex;
                flex:           1;
                flex-direction: column;
            }
            label {
                display:       flex;
                flex:          initial;
                font-size:     var(--ca-font-size--small);
                font-weight:   var(--ca-ui-form-label--font-weight);
                margin-bottom: 5px;
                color:         var(--ca-ui-form-label--fg);
            }
            label:empty {
                display: none;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div
                class="ca-form-field"
            >
                <label></label>
                <div class="ca-form-field-container">
                </div>
            </div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const caFormFieldContainer = this.shadowRoot.querySelector('.ca-form-field-container');
        caFormFieldContainer.attachShadow({mode: 'open'});

        const style = document.createElement('style');

        style.textContent = `
            * {
                outline: none;
            }
            :host {
                display:        flex;
                flex:           1;
                flex-direction: column;
            }
            .ca-button-container {
                display:        flex;
                flex:           1;
                flex-direction: row;
                align-self:     stretch;
                min-height:     40px;
            }
            .ca-button-container.separate-v-large {
                margin-bottom: 8px;
            }
            .ca-button-container.separate-v-medium {
                margin-bottom: 4px;
            }
            .ca-button-container.separate-v-small {
                margin-bottom: 2px;
            }
            .ca-button-container.border-radius .ca-button.is-first-button {
                border-top-left-radius:  5px;
                border-top-right-radius: 5px;
            }
            .ca-button-container.border-radius .ca-button.is-last-button {
                border-bottom-left-radius:  5px;
                border-bottom-right-radius: 5px;
            }
            .ca-button {
                display:             flex;
                flex:                1;
                align-items:         center;
                min-height:          40px;
                text-decoration:     none;
                color:               var(--ca-ui-form-buttonlist-button-fg);
                background-color:    var(--ca-ui-form-buttonlist-button-bg);
                border-width:        var(--ca-ui-form-buttonlist-button-border-width);
                border-bottom-width: 0;
                border-color:        var(--ca-ui-form-buttonlist-button-border-color);
                border-top-color:    var(--ca-ui-form-buttonlist-button-border-color-separator);
                border-style:        solid;
            }
            .ca-button.is-first-button {
                border-top-color: var(--ca-ui-form-buttonlist-button-border-color);
            }
            .ca-button.is-last-button {
                border-bottom-width: var(--ca-ui-form-buttonlist-button-border-width);
            }
            .ca-button:hover {
                color:            var(--ca-ui-form-buttonlist-button-fg--hover);
                background-color: var(--ca-ui-form-buttonlist-button-bg--hover);
                border-color:     var(--ca-ui-form-buttonlist-button-border-color--hover);
                border-top-color: var(--ca-ui-form-buttonlist-button-border-color-separator);
            }
            .ca-button:focus,
            .ca-button.active {
                color:            var(--ca-ui-form-buttonlist-button-fg--focus);
                background-color: var(--ca-ui-form-buttonlist-button-bg--focus);
                border-color:     var(--ca-ui-form-buttonlist-button-border-color--focus);
                border-top-color: var(--ca-ui-form-buttonlist-button-border-color-separator);
            }
            .ca-button.is-first-button:focus,
            .ca-button.is-first-button.active {
                border-top-color: var(--ca-ui-form-buttonlist-button-border-color);
            }
            .ca-button-label {
                display: flex;
                flex:    1;
            }
            .ca-button-label.align-h-center {
                justify-content: center;
            }
            .ca-button-label.active-placeholder {
                margin-left: 40px;
            }
            .ca-inline-button {
                flex:            initial;
                display:         flex;
                align-items:     center;
                justify-content: center;
                min-width:       40px;
                min-height:      40px;
            }

            [class^="ca-icon"] {
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            .ca-icon-check:before {
                content: "\\e116";
            }
        `;

        caFormFieldContainer.shadowRoot.appendChild(style);
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.host.caFormLayoutEventAggregator.subscribe(
                'CaFormLayoutChild:attached:response',
                (params) => this.receiveCaFormLayoutChildAttachedResponse(params)
            )
        );
        this.host.caFormLayoutEventAggregator.publish(
            'CaFormLayoutChild:attached:request',
            {
                elRowIdx: parseInt(this.getAttribute('row-idx'), 10),
                elColIdx: parseInt(this.getAttribute('col-idx'), 10)
            }
        );
    }

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }

        const caFormFieldContainer = this.shadowRoot.querySelector('.ca-form-field-container');
        const formButtons = caFormFieldContainer.shadowRoot.querySelectorAll('a');
        let btnIdx = 0;

        for (const formButton of formButtons) {
            formButton.removeEventListener(
                'click',
                this.formButtonEventListeners[btnIdx]
            );
            btnIdx++;
        }
    }

    renderButtons() {
        const caFormFieldContainer = this.shadowRoot.querySelector('.ca-form-field-container');
        const tmpl = document.createElement('template');
        let layoutStr = '';
        let buttonIdx = 0;
        let lastButtonIdx = this.config.buttons.length - 1;

        for (const button of this.config.buttons) {
            let activeBtnStr      = '';
            let activePlaceholder = '';
            let activeCls         = '';

            if (this.config.showActive) {
                if (button[this.config.valueField] === this.value) {
                    activeBtnStr += `
                        <i active-indicator class="ca-icon-check ca-inline-button"></i>
                    `;
                    activeCls = 'active';
                } else {
                    activePlaceholder = 'active-placeholder';
                }
            }

            layoutStr += `
                <div class="ca-button-container ${this.config.separateV ? 'separate-v-' + this.config.separateV : ''} ${this.config.borderRadius ? 'border-radius' : ''}">
                    <a
                        href    = "#"
                        class   = "ca-button ${activeCls} ${button.class ? button.class : ''} ${buttonIdx === 0 ? 'is-first-button' : ''} ${lastButtonIdx === buttonIdx ? 'is-last-button' : ''}"
                        btn-idx = "${buttonIdx}"
                    >
                        ${activeBtnStr}
                        <span class="ca-button-label ${activePlaceholder} ${button.alignH ? 'align-h-' + button.alignH : ''}">${button.title}</span>
                    </a>
                </div>
            `;

            buttonIdx++;
        }

        tmpl.innerHTML = layoutStr;
        caFormFieldContainer.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const formButtons = caFormFieldContainer.shadowRoot.querySelectorAll('a');
        let btnIdx = 0;
        this.formButtonEventListeners = [];

        for (const formButton of formButtons) {
            this.formButtonEventListeners.push((e) => this.receiveUserInput(e));
            formButton.addEventListener(
                'click',
                this.formButtonEventListeners[btnIdx]
            );
            btnIdx++;
        }
    }

    updateButtons() {
        const caFormFieldContainer = this.shadowRoot.querySelector('.ca-form-field-container');
        const buttons = caFormFieldContainer.shadowRoot.querySelectorAll('.ca-button-container');
        let btnIdx = 0;

        for (const button of this.config.buttons) {
            if (this.config.showActive) {
                const existingIndicator = buttons[btnIdx].querySelector('[active-indicator]');
                const caBtn = buttons[btnIdx].querySelector('.ca-button');

                if (button[this.config.valueField] === this.value) {
                    if (!existingIndicator) {
                        let indicator = document.createElement('i');
                        indicator.setAttribute('active-indicator', 'true');
                        indicator.classList.add('ca-icon-check', 'ca-inline-button');

                        caBtn.prepend(indicator);
                        caBtn.classList.add('active');
                        buttons[btnIdx].querySelector('.ca-button-label').classList.remove('active-placeholder');
                    }
                } else {
                    if (existingIndicator) {
                        caBtn.removeChild(existingIndicator);
                        caBtn.classList.remove('active');
                        buttons[btnIdx].querySelector('.ca-button-label').classList.add('active-placeholder');
                    }
                }
            }

            btnIdx++;
        }
    }

    // ---------------------------------------------------------------------

    receiveCaFormLayoutChildAttachedResponse({
        rowIdx = 0,
        colIdx = 0,
        config = {},
        value  = false
    }) {
        if (
            rowIdx === parseInt(this.getAttribute('row-idx'), 10) &&
            colIdx === parseInt(this.getAttribute('col-idx'), 10)
        ) {
            this.config.fieldId       = config?.fieldId || '';
            this.config.valueField    = config?.valueField || 'value';
            this.config.title         = config?.title || '';
            this.config.buttons       = config?.buttons || [];
            this.config.separateV     = config?.separateV || '';
            this.config.borderRadius  = config?.borderRadius || false;
            this.config.submitOnClick = config?.submitOnClick || false;
            this.config.showActive    = config?.showActive || false;

            if (config.style) {
                const caFormFieldContainer = this.shadowRoot.querySelector('.ca-form-field-container');
                caFormFieldContainer.shadowRoot.firstChild.innerHTML += config.style;
            }

            const fieldLabel = this.shadowRoot.querySelector('label');

            if (this.config.title) {
                fieldLabel.innerHTML = this.config.title + ':';
            } else {
                fieldLabel.innerHTML = '';
            }

            this.value = value;

            this.renderButtons();
        }
    }

    receiveUserInput(e) {
        let target   = e.target;
        let depthIdx = 0;

        while (
            target.hasAttribute('btn-idx') === false &&
            depthIdx < 10
        ) {
            target = target.parentNode;

            depthIdx++;
        }

        if (target.hasAttribute('btn-idx')) {
            const btnIdx = parseFloat(target.getAttribute('btn-idx'));

            if (this.config.buttons.length >= btnIdx) {
                const btn = this.config.buttons[btnIdx];

                this.value = btn[this.config.valueField];

                if (this.config.showActive) {
                    this.updateButtons();
                }

                this.host.caFormLayoutEventAggregator.publish(
                    'CaFormLayoutChild:userInput:request',
                    {
                        rowIdx:     parseInt(this.getAttribute('row-idx'), 10),
                        colIdx:     parseInt(this.getAttribute('col-idx'), 10),
                        fieldInfo:  this.config.fieldInfo,
                        fieldId:    this.config.fieldId,
                        fieldValue: btn[this.config.valueField]
                    }
                );

                if (this.config.submitOnClick) {
                    this.host.caFormLayoutEventAggregator.publish(
                        'CaFormLayout:submit:request'
                    );
                }
            }
            e.preventDefault();
        }
    }
}

customElements.define('ca-form-button-list', CaFormButtonList);

export {CaFormButtonList};

import {CaToolQuery}           from '../ca-tools/ca-tool-query.js';
import {CaToolEventAggregator} from '../ca-tools/ca-tool-event-aggregator';
import {CaAppConfig}           from '../ca-app/ca-app-config';
import {CaAppEventAggregators} from '../ca-app/ca-app-event-aggregators';
import {CaAppLoginState}       from '../ca-app/ca-app-login-state';

class RezeptePage extends HTMLElement {

    arten                = [];
    filterByArtId        = '';
    filterByKategorie    = '';
    filterBySearchTerm   = '';
    sizePoint            = 10;
    rezepte              = [];
    rezeptEventListeners = [];

    constructor() {
        super();
        this.caUiLoadingIndicatorEventAggregator = new CaToolEventAggregator();
        this.caUiSidebarEventAggregator          = new CaToolEventAggregator();
        this.stageEventAggregator                = new CaToolEventAggregator();
        this.subscriptions                       = [];

        this.subscriptions.push(
            this.stageEventAggregator.subscribe(
                'CaStageController:stageActivated:request',
                (params) => this.answerCaStageControllerStageActivatedRequest(params)
            )
        );
        this.subscriptions.push(
            this.caUiLoadingIndicatorEventAggregator.subscribe(
                'CaUiLoadingIndicator.config.set:request',
                (params) => this.receiveCaUiLoadingIndicatorConfigSetRequest(params)
            )
        );
        this.subscriptions.push(
            this.caUiSidebarEventAggregator.subscribe(
                'CaUiSidebar.config.set:request',
                (params) => this.receiveCaUiSidebarConfigSetRequest(params)
            )
        );
        this.subscriptions.push(
            this.caUiSidebarEventAggregator.subscribe(
                'CaAppUiFilterArt.reloadList:request',
                (params) => this.receiveCaAppUiFilterArtRequest(params)
            )
        );
        this.subscriptions.push(
            this.caUiSidebarEventAggregator.subscribe(
                'CaAppUiFilterKategorie.reloadList:request',
                (params) => this.receiveCaAppUiFilterKategorieRequest(params)
            )
        );
        this.subscriptions.push(
            CaAppEventAggregators.eventAggregatorToolbar.subscribe(
                'CaUiToolbar.resize:request',
                (params) => this.receiveCaUiToolbarResizeRequest(params)
            )
        );

        this.attachShadow({mode: 'open'});

        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                overflow:       auto;
                display:        flex;
                flex:           1;
                flex-direction: column;
                color:          var(--ca-ui-viewport-color--fg);
            }
            #rezepte {
                position:       absolute;
                left:           0;
                top:            0;
                right:          0;
                bottom:         0;
                overflow:       auto;
                display:        flex;
                flex-direction: column;
            }
            #rezepte.has-sidebar {
                right: calc(var(--ca-ui-sidebar-width) + 1px);
            }
            #rezepte a {
                min-height:      44px;
                display:         flex;
                flex-direction:  column;
                justify-content: center;
                text-decoration: none;
                color:           var(--ca-ui-viewport-color--fg);
                padding:         0 16px;
                border-bottom:   1px #eee solid;
            }
            #rezepte a:last-child {
                border-bottom: none;
            }
            #rezepte a:hover {
                background-color: #f4ebf4;
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <ca-ui-sidebar sidebar-id="filters"></ca-ui-sidebar>
            <div id="rezepte"></div>
        `;
        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ---------------------------------------------------------------------

    async connectedCallback() {
        this.receiveSetStatusLoadingRequest();

        const wsLoad = await CaAppConfig.loadConfiguration();
        if (wsLoad.error === '0') {
            // Automatischen Login durchführen
            const hash =CaAppLoginState.getLocalUserHash();

            if (hash !== null) {
                const ws = await CaToolQuery.checkLoginHash({
                    hash: hash
                });

                if (ws.error === '0') {
                    if (ws.response.display_name !== '') {
                        CaAppLoginState.isLoggedIn = true;
                        this.answerCaStageControllerStageActivatedRequest();
                    }
                }
            }

            // Arten laden
            const wsArten = await CaToolQuery.getArten();

            if (wsArten.error === '0') {
                CaAppConfig.setArten(wsArten.response);
                this.receiveCaUiSidebarConfigSetRequest();
            } else {
                CaAppEventAggregators.eventAggregatorStage.publish(
                    'CaStageController:showFatalError:request',
                    wsArten
                );
            }

            await this.loadRezepte();
            this.receiveSetStatusNotLoadingRequest();
        }
    }

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    // ---------------------------------------------------------------------

    async answerCaStageControllerStageActivatedRequest(params) {
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__status'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'right',
                id:      '__edit'
            }
        );

        const sectionRight = [];

        if (CaAppLoginState.isLoggedIn) {
            sectionRight.push({
                type:     'button',
                id:       '__create',
                label:    'Neu',
                icon:     'ca-icon-plus',
                callback: () => {
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:addStage:request',
                        {
                            id:           'rezept-erstellen',
                            stageElement: 'rezept-create-page'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:gotoStage:request',
                        {
                            id:        'rezept-erstellen',
                            params: {
                                originStage: 'rezepte'
                            }
                        }
                    );
                }
            });
        } else {
            sectionRight.push({
                type:     'button',
                id:       '__login',
                label:    'Anmelden',
                icon:     'ca-icon-modul_AV',
                callback: () => {
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:addStage:request',
                        {
                            id:           'anmeldung',
                            stageElement: 'anmeldung-page'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:gotoStage:request',
                        {
                            id:     'anmeldung',
                            params: {
                                originStage: 'rezepte'
                            }
                        }
                    );
                }
            });
        }

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__suche'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.addItem:request',
            {
                sectionCenter: [
                    {
                        id:       '__suche',
                        type:     'searchbox',
                        label:    'Rezepte suchen...',
                        value:    this.filterBySearchTerm,
                        callback: (value) => {
                            this.onRezeptSearch(value);
                        }
                    }
                ]
            }
        );

        const sidebarButton = await this.caUiSidebarEventAggregator.publishRequest(
            'CaUiSidebar.send.sidebarButton:request'
        );

        sectionRight.push(sidebarButton);

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.config.set:response',
            {
                sectionRight: sectionRight
            }
        );

        if (params?.reload) {
            await this.loadRezepte();
        }
    }

    receiveCaUiSidebarConfigSetRequest() {
        if (CaAppConfig.getArten().length > 0) {
            this.caUiSidebarEventAggregator.publish(
                'CaUiSidebar.config.set:response',
                {
                    position: 'right',
                    items:    [
                        {
                            itemId:  'art',
                            element: 'ca-app-ui-filter-art'
                        },
                        {
                            itemId:  'art',
                            element: 'ca-app-ui-filter-kategorie'
                        }
                    ]
                }
            );
        }
    }

    onRezeptSearch(value) {
        this.filterBySearchTerm = value;
        this.loadRezepte();
    }

    receiveCaAppUiFilterArtRequest({
        artId = ''
    }) {
        this.filterByArtId = artId;
        this.loadRezepte();
    }

    receiveCaAppUiFilterKategorieRequest({
        kategorie = ''
    }) {
        this.filterByKategorie = kategorie;
        this.loadRezepte();
    }

    receiveCaUiToolbarResizeRequest({
        sizePoint = 10
    }) {
        this.sizePoint = sizePoint;

        const rezepteElem = this.shadowRoot.querySelector('#rezepte');
        const sidebarElem = this.shadowRoot.querySelector('ca-ui-sidebar');

        if (this.sizePoint < 20) {
            rezepteElem.classList.remove('has-sidebar');
            sidebarElem.classList.add('hidden');
        } else {
            rezepteElem.classList.add('has-sidebar');
            sidebarElem.classList.remove('hidden');
            sidebarElem.classList.remove('modal');
        }
    }

    async receiveCaUiLoadingIndicatorConfigSetRequest() {
        this.caUiLoadingIndicatorEventAggregator.publish(
            'CaUiLoadingIndicator.setLabel.request',
            {
                label: 'Lade Rezepte'
            }
        );
    }

    async loadRezepte() {
        CaToolQuery.setFilterBySearchTerm(this.filterBySearchTerm);
        CaToolQuery.setFilterByArtId(this.filterByArtId);
        CaToolQuery.setFilterByKategorie(this.filterByKategorie);

        const wsRezepte = await CaToolQuery.getRezepte();

        if (wsRezepte.error === '0') {
            this.rezepte = wsRezepte.rezepte;
            this.renderRezepte();
        } else {
            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:showFatalError:request',
                wsRezepte
            );
        }
    }

    renderRezepte() {
        const rezepteContainer = this.shadowRoot.querySelector('#rezepte');
        let rezepteElems       = rezepteContainer.querySelectorAll('a');
        let rezeptIdx          = 0;

        for (const elem of rezepteElems) {
            elem.removeEventListener(
                'click',
                this.rezeptEventListeners[rezeptIdx]
            );
            rezeptIdx++;
        }

        let rezepteStr = '';

        for (const rezept of this.rezepte) {
            rezepteStr += `
            <a
                href    = "#"
                data-id = "${rezept.id}"
            >
                ${rezept.name}
            </a>
            `;
        }

        rezepteContainer.innerHTML = rezepteStr;

        this.rezeptEventListeners = [];
        rezepteElems = rezepteContainer.querySelectorAll('a');

        for (const rezept of rezepteElems) {
            this.rezeptEventListeners.push(
                rezept.addEventListener(
                    'click',
                    (e) => {
                        CaAppEventAggregators.eventAggregatorStage.publish(
                            'CaStageController:gotoStage:request',
                            {
                                id:     'rezept',
                                params: {
                                    id: rezept.getAttribute('data-id')
                                }
                            }
                        );
                        e.preventDefault();
                    }
                )
            );
        }
    }

    /**
     * Aktiviert die Ladeanzeige für das Formular.
     */
    receiveSetStatusLoadingRequest() {
        this.isLoading = true;

        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <ca-ui-loading-indicator></ca-ui-loading-indicator>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    /**
     * Deaktiviert die Ladeanzeige für das Formular.
     */
    receiveSetStatusNotLoadingRequest() {
        this.isLoading = false;

        for (const childNode of this.shadowRoot.childNodes) {
            if (childNode.nodeName === 'CA-UI-LOADING-INDICATOR') {
                this.shadowRoot.removeChild(childNode);
                break;
            }
        }
    }
}

customElements.define('rezepte-page', RezeptePage);

export {RezeptePage};

import { CaTools }               from "../../ca-tools/ca-tools";
import { CaToolEventAggregator } from "../../ca-tools/ca-tool-event-aggregator";

/**
 * Stellt eine Toolbar zur Verfügung.
 *
 * Konfiguration:
 *
 * config: {
 *     sectionLeft:   [ ...sectionObjekt(e) ],
 *     sectionCenter: [ ...sectionObjekt(e) ],
 *     sectionRight:  [ ...sectionObjekt(e) ]
 * }
 *
 * sectionObjekt:
 *
 *     {
 *         type:  'button'|'text'|'image',
 *         label: string
 *     }
 */
class CaUiToolbar extends HTMLElement {

    constructor() {
        super();

        this.config = {
            sectionLeft:   [],
            sectionCenter: [],
            sectionRight:  []
        };
        this.eventListeners = {
            sectionLeft:   [],
            sectionCenter: [],
            sectionRight:  []
        };
        this.resizeEventAggregator = new CaToolEventAggregator();
        this.resizeObserver        = null;
        this.sizePoint             = 10;
        this.subscriptions         = [];

        this.subscriptions.push(
            this.resizeEventAggregator.subscribe(
                'CaTools.resizeObserver.resize:request',
                params => this.answerCaToolsResizeObserverResizeRequest(params)
            )
        );

        this.attachShadow({mode: 'open'});
        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                position:         relative;
                top:              0;
                overflow:         hidden;
                display:          flex;
                flex:             1;
                flex-direction:   row;
                max-height:       44px;
                min-height:       44px;
                font-size:        var(--ca-font-size--large);
                color:            var(--ca-ui-toolbar-fg);
                background-color: var(--ca-ui-toolbar-bg);
            }
            :host(.remove) {
                animation-duration:        0.5s;
                animation-name:            slideOutToTop;
                animation-iteration-count: 1;
            }
            @media print{
                :host {
                    display: none;
                }
            }
            .left {
                display:          flex;
                flex:             1;
                flex-direction:   row;
                align-items:      center;
                justify-content:  flex-start;
            }
            .center {
                display:         flex;
                flex:            initial;
                flex-direction:  row;
                align-items:     center;
                justify-content: flex-end;
                overflow:        hidden;
                margin:          0 12px;
            }
            .right {
                display:          flex;
                flex:             1;
                flex-direction:   row;
                align-items:      center;
                justify-content:  flex-end;
            }

            a {
                display:          flex;
                flex:             initial;
                min-height:       36px;
                min-width:        22px;
                flex-direction:   row;
                align-items:      center;
                justify-content:  center;
                text-decoration:  none;
                color:            var(--ca-ui-toolbar-btn-fg);
                background-color: var(--ca-ui-toolbar-btn-bg);
                padding:          0 8px;
                border:           1px rgba(255, 255, 255, 0.5) solid;
            }
            .left a {
                margin-left: 3px;
            }
            .right a {
                margin-right: 3px;
            }
            a:hover {
                color:            var(--ca-ui-toolbar-btn-fg--hover);
                background-color: var(--ca-ui-toolbar-btn-bg--hover);
            }
            a i {
                display:         flex;
                flex-direction:  column;
                min-height:      32px;
                justify-content: center;
                align-items:     center;
            }
            a .label {
                font-size:    var(--ca-font-size--btn-label);
                color:        var(--ca-ui-toolbar-fg);
                padding-left: 6px;
            }
            span.bold {
                font-weight: 600;
            }

            [class^="ca-icon"] {
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            .ca-icon-chevron-thin-left:before {
                content: "\\e90e";
            }
            .ca-icon-edit:before {
                content: "\\e916";
            }
            .ca-icon-modul_AV:before {
                content: "\\e604";
            }
            .ca-icon-plus:before {
                content: "\\e114";
            }
            .ca-icon-trash:before {
                content: "\\f4c4";
            }
            .ca-icon-check:before {
              content: "\\e116";
            }
            .ca-icon-menu:before {
              content: "\\e120";
            }
            .ca-icon-close:before {
              content: "\\e90f";
            }

            .center span {
                font-size: 24px;
            }

            #ToolbarSearchForm {
                position: relative;
            }
            #ToolbarSearchForm input {
                border:        none;
                border-radius: 2px;
                height:        34px;
                padding:       0 8px;
                margin:        0;
            }
            #ToolbarSearchForm input:focus {
                outline:          none;
                background-color: #ff9;
            }
            #ToolbarSearchForm.has-search input {
                padding-right: 44px;
            }
            #ToolbarSearchForm a {
                display:          none;
                background-color: transparent;
                border:           none;
                position:         absolute;
                right:            0;
                top:              0;
                color:            #000;
            }
            #ToolbarSearchForm a:hover {
                background-color: rgba(0, 0, 0, 0.15);
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        this.resizeObserver = CaTools.registerResizeObserver({
            resizeEventAggregator: this.resizeEventAggregator
        })
        this.resizeObserver.observe(this.shadowRoot.host);
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        this.subscriptions.push(
            this.parentNode.host.toolbarEventAggregator.subscribe(
                'toolbar.config.set:response',
                (params) => this.receiveToolbarConfigSetResponse(params)
            )
        );
        this.subscriptions.push(
            this.parentNode.host.toolbarEventAggregator.subscribe(
                'toolbar.update.addItem:request',
                (params) => this.answerToolbarUpdateAddItemRequest(params)
            )
        );
        this.subscriptions.push(
            this.parentNode.host.toolbarEventAggregator.subscribe(
                'toolbar.update.deleteItem:request',
                (params) => this.answerToolbarUpdateDeleteItemRequest(params)
            )
        );
        this.subscriptions.push(
            this.parentNode.host.toolbarEventAggregator.subscribe(
                'toolbar.update.hideItem:request',
                (params) => this.answerToolbarUpdateHideItemRequest(params)
            )
        );
        this.subscriptions.push(
            this.parentNode.host.toolbarEventAggregator.subscribe(
                'toolbar.update.showItem:request',
                (params) => this.answerToolbarUpdateShowItemRequest(params)
            )
        );
        this.parentNode.host.toolbarEventAggregator.publish('toolbar.config.set:request');
    }

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    // ---------------------------------------------------------------------

    renderSectionsUpdateSection({
        sectionConfig   = {},
        shadowRoot      = '',
        clearShadowRoot = true
    }) {
        if (clearShadowRoot) {
            shadowRoot.innerHTML = '';
        }

        const checkConditions = (item) => {
            let conditionsMet = true;

            if (item.conditions) {
                conditionsMet = false;
                let nrOfConditionsMet = 0;

                for (const condition of item.conditions) {
                    if (condition.property) {
                        if (condition.operator === '<') {
                            if (this[condition.property] < condition.value) {
                                nrOfConditionsMet++;
                            }
                        } else if (condition.operator === '>') {
                            if (this[condition.property] > condition.value) {
                                nrOfConditionsMet++;
                            }
                        } else if (condition.operator === '=') {
                            if (this[condition.property] === condition.value) {
                                nrOfConditionsMet++;
                            }
                        }
                    }
                }

                if (nrOfConditionsMet === item.conditions.length) {
                    conditionsMet = true;
                }
            }

            return conditionsMet;
        };

        for (const item of sectionConfig) {
            if (item.type === 'button') {
                const conditionsMet = checkConditions(item);

                if (conditionsMet) {
                    let iconStr = '';

                    if (item.icon) {
                        iconStr += `<i class="${item.icon}"></i>`;
                    }

                    let labelStr = '';

                    if (this.sizePoint > 10) {
                        labelStr = `<span class="label">${item.label}</span>`;
                    }

                    const tmpl = document.createElement('template');
                    tmpl.innerHTML = `
                        <a
                            href = "#"
                        >
                            ${iconStr}
                            ${labelStr}
                        </a>
                    `;

                    shadowRoot.appendChild(tmpl.content.cloneNode(true));
                }
            } else if (item.type === 'text') {
                const conditionsMet = checkConditions(item);

                if (conditionsMet) {
                    let clsStr = '';

                    if (item.fontWeight) {
                        clsStr += ' class="';
                        clsStr += 'bold';
                    }

                    if (clsStr !== '') {
                        clsStr += '"';
                    }

                    const tmpl = document.createElement('template');
                    tmpl.innerHTML = `
                        <span${clsStr}>${item.label}</span>
                    `;

                    shadowRoot.appendChild(tmpl.content.cloneNode(true));
                }
            } else if (item.type === 'image') {
                const conditionsMet = checkConditions(item);

                if (conditionsMet) {
                    const tmpl = document.createElement('template');
                    tmpl.innerHTML = `
                        <img
                            src   = "./resources/images/${item.src}"
                            style = "${item.width ? 'width: ' + item.width + 'px;' : ''}"
                            title = "${item.title}"
                        >
                    `;

                    shadowRoot.appendChild(tmpl.content.cloneNode(true));
                }
            } else if (item.type === 'searchbox') {
                const conditionsMet = checkConditions(item);

                if (conditionsMet) {
                    const tmpl = document.createElement('template');
                    tmpl.innerHTML = `
                        <form id="ToolbarSearchForm" class="${item.value ? 'has-search' : ''}">
                            <input
                                type        = "text"
                                placeholder = "${item.label}"
                                value       = "${item.value}"
                            >
                            <a
                                href  = "#"
                                class = "ca-icon-close"
                                style = "${item.value ? 'display: flex;' : ''}"
                            >
                            </a>
                        </form>
                    `;

                    shadowRoot.appendChild(tmpl.content.cloneNode(true));
                }
            }

        }
    }

    renderSections() {
        const addEventListenersToSection = (items, sectionId) => {
            let itemIdx = 0;

            for (const item of this.config[sectionId]) {
                if (item.type === 'button') {
                    if (items[itemIdx]) {
                        const eventListener = () => this.onButtonPress(item);

                        items[itemIdx].addEventListener(
                            'click',
                            eventListener
                        );

                        this.eventListeners[sectionId].push(eventListener);
                    }
                } else if (item.type === 'searchbox') {
                    if (items[itemIdx]) {
                        const formElem            = this.shadowRoot.querySelector('#ToolbarSearchForm');
                        const inputElem           = formElem.querySelector('input');
                        const deleteElem          = formElem.querySelector('a');
                        const eventListener       = (event) => this.onSearchFormSubmit(item, inputElem.value, event);
                        const eventListenerDelete = (event) => this.onResetSearchForm(item, inputElem.value, event);

                        formElem.addEventListener(
                            'submit',
                            eventListener
                        );
                        deleteElem.addEventListener(
                            'click',
                            eventListenerDelete
                        );

                        this.eventListeners[sectionId].push(eventListener);
                        this.eventListeners[sectionId].push(eventListenerDelete);
                    }
                }

                itemIdx++;
            }
        }

        const removeEventListenersFromSection = (items, sectionId) => {
            let itemIdx = 0;

            for (const item of this.config[sectionId]) {
                if (item.type === 'button') {
                    if (items[itemIdx]) {
                        items[itemIdx].removeEventListener(
                            'click',
                            this.eventListeners[sectionId][itemIdx]
                        );
                    }
                } else if (item.type === 'searchbox') {
                    const formElem   = this.shadowRoot.querySelector('#ToolbarSearchForm');
                    const deleteElem = formElem.querySelector('a');
                    formElem.removeEventListener(
                        'submit',
                        this.eventListeners[sectionId][itemIdx]
                    );
                    deleteElem.removeEventListener(
                        'click',
                        this.eventListeners[sectionId][itemIdx + 1]
                    );
                }

                itemIdx++;
            }
        }

        removeEventListenersFromSection(
            this.shadowRoot.children[1].children,
            'sectionLeft'
        );
        this.renderSectionsUpdateSection({
            sectionConfig: this.config.sectionLeft,
            shadowRoot:    this.shadowRoot.children[1]
        });
        addEventListenersToSection(
            this.shadowRoot.children[1].children,
            'sectionLeft'
        );

        removeEventListenersFromSection(
            this.shadowRoot.children[2].children,
            'sectionCenter'
        );
        this.renderSectionsUpdateSection({
            sectionConfig: this.config.sectionCenter,
            shadowRoot:    this.shadowRoot.children[2]
        });
        addEventListenersToSection(
            this.shadowRoot.children[2].children,
            'sectionCenter'
        );

        removeEventListenersFromSection(
            this.shadowRoot.children[3].children,
            'sectionRight'
        );
        this.renderSectionsUpdateSection({
            sectionConfig: this.config.sectionRight,
            shadowRoot:    this.shadowRoot.children[3]
        });
        addEventListenersToSection(
            this.shadowRoot.children[3].children,
            'sectionRight'
        );
    }

    // ---------------------------------------------------------------------

    onButtonPress(item) {
        if (item.callback) {
            item.callback();
        }
    }

    onSearchFormSubmit(item, value, event) {
        event.preventDefault();
        const formElem   = this.shadowRoot.querySelector('#ToolbarSearchForm');
        const deleteElem = formElem.querySelector('a');

        if (value === '') {
            deleteElem.style.display = 'none';
            formElem.classList.remove('has-search');
        } else {
            deleteElem.style.display = 'flex';
            formElem.classList.add('has-search');
        }

        if (item.callback) {
            item.callback(value);
        }
    }

    onResetSearchForm(item, value, event) {
        event.preventDefault();
        const formElem   = this.shadowRoot.querySelector('#ToolbarSearchForm');
        const inputElem  = formElem.querySelector('input');
        const deleteElem = formElem.querySelector('a');

        inputElem.value = '';
        formElem.classList.remove('has-search');
        deleteElem.style.display = 'none';

        if (item.callback) {
            item.callback('');
        }
    }

    receiveToolbarConfigSetResponse(params) {
        if (params?.sectionLeft) {
            this.config.sectionLeft = params.sectionLeft;
        }
        if (params?.sectionCenter) {
            this.config.sectionCenter = params.sectionCenter;
        }
        if (params?.sectionRight) {
            this.config.sectionRight = params.sectionRight;
        }

        this.renderSections();
    }

    answerCaToolsResizeObserverResizeRequest({
        sizePoint = 10
    }) {
        this.sizePoint = sizePoint;

        this.renderSections();
        this.parentNode.host.toolbarEventAggregator.publish(
            'CaUiToolbar.resize:request',
            {
                sizePoint: this.sizePoint
            }
        )
    }

    answerToolbarUpdateAddItemRequest(config) {
        for (const section in config) {
            let shadowRoot = [];

            if (section === 'sectionLeft') {
                shadowRoot = this.shadowRoot.children[1];
            } else if (section === 'sectionCenter') {
                shadowRoot = this.shadowRoot.children[2];
            } else if (section === 'sectionRight') {
                shadowRoot = this.shadowRoot.children[3];
            }

            for (const item of config[section]) {
                this.config[section].push(item);

                this.renderSectionsUpdateSection({
                    sectionConfig:   [item],
                    shadowRoot:      shadowRoot,
                    clearShadowRoot: false
                });
            }
        }
    }

    answerToolbarUpdateDeleteItemRequest({
        section = 'left',
        id      = ''
    }) {
        let sectionId = 'sectionLeft';
        let shadowRoot  = [];

        if (section === 'left') {
            shadowRoot = this.shadowRoot.children[1];
        } else if (section === 'center') {
            sectionId  = 'sectionCenter';
            shadowRoot = this.shadowRoot.children[2];
        } else if (section === 'right') {
            sectionId  = 'sectionRight';
            shadowRoot = this.shadowRoot.children[3];
        }

        let itemIdx         = 0;
        let itemIdxToRemove = -1;

        for (const item of this.config[sectionId]) {
            if (item.id === id) {
                if (item.type === 'button') {
                    shadowRoot.children[itemIdx].removeEventListener(
                        'click',
                        this.eventListeners[sectionId][itemIdx]
                    );
                    this.eventListeners[sectionId].splice(itemIdx, 1);
                } else if (item.type === 'searchbox') {
                    const formElem   = this.shadowRoot.querySelector('#ToolbarSearchForm');
                    const deleteElem = formElem.querySelector('a');

                    formElem.children[itemIdx].removeEventListener(
                        'submit',
                        this.eventListeners[sectionId][itemIdx]
                    );
                    deleteElem.removeEventListener(
                        'click',
                        this.eventListeners[sectionId][itemIdx + 1]
                    );
                    this.eventListeners[sectionId].splice(itemIdx, 2);
                }

                shadowRoot.removeChild(shadowRoot.children[itemIdx]);
                itemIdxToRemove = itemIdx;
            }

            itemIdx++;
        }

        if (itemIdxToRemove > -1) {
            this.config[sectionId].splice(itemIdxToRemove, 1);
        }
    }

    answerToolbarUpdateHideItemRequest({
        section = 'left',
        id      = ''
    }) {
        let sectionId = 'sectionLeft';
        let shadowRoot  = [];

        if (section === 'left') {
            shadowRoot = this.shadowRoot.children[1];
        } else if (section === 'center') {
            sectionId  = 'sectionCenter';
            shadowRoot = this.shadowRoot.children[2];
        } else if (section === 'right') {
            sectionId  = 'sectionRight';
            shadowRoot = this.shadowRoot.children[3];
        }

        let itemIdx = 0;

        for (const item of this.config[sectionId]) {
            if (item.id === id) {
                shadowRoot.children[itemIdx].style.display = 'none';
                break;
            }

            itemIdx++;
        }
    }

    /**
     * Blendet ein Objekt in der Toolbar ein.
     *
     * @param {object}                      options - Optionen
     * @param {'left' | 'center' | 'right'} options.section - Sektion
     * @param {string}                      options.id
     */
    answerToolbarUpdateShowItemRequest({
        section = 'left',
        id      = ''
    }) {
        let sectionId = 'sectionLeft';
        let shadowRoot  = [];

        if (section === 'left') {
            shadowRoot = this.shadowRoot.children[1];
        } else if (section === 'center') {
            sectionId  = 'sectionCenter';
            shadowRoot = this.shadowRoot.children[2];
        } else if (section === 'right') {
            sectionId  = 'sectionRight';
            shadowRoot = this.shadowRoot.children[3];
        }

        let itemIdx = 0;

        for (const item of this.config[sectionId]) {
            if (item.id === id) {
                shadowRoot.children[itemIdx].style.display = 'flex';
                break;
            }

            itemIdx++;
        }
    }
}

customElements.define('ca-ui-toolbar', CaUiToolbar);

export {CaUiToolbar};

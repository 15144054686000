import {CaAppConfig} from '../ca-app/ca-app-config';

class CaAppUiFilterKategorie extends HTMLElement {

    constructor() {
        super();

        this.kategorie      = '';
        this.eventListeners = [];
        this.subscriptions  = [];

        this.attachShadow({mode: 'open'});

        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                display: flex;
                margin:  8px;
            }
            .ca-app-ui-filter-kategorie {
                display:        flex;
                flex:           1;
                flex-direction: column;
                flex-wrap:      nowrap;
                border-radius:  3px;
                border:         1px #b434b2 solid;
            }
            .ca-app-ui-filter-kategorie a {
                flex:            1;
                color:           #b434b2;
                text-decoration: none;
                border-bottom:   1px #f8f1f8 solid;
                padding:         8px;
            }
            .ca-app-ui-filter-kategorie a:first-child {
                border-top-left-radius:  3px;
                border-top-right-radius: 3px;
            }
            .ca-app-ui-filter-kategorie a:last-child {
                border-bottom:              none;
                border-bottom-left-radius:  3px;
                border-bottom-right-radius: 3px;
            }
            .ca-app-ui-filter-kategorie a:hover {
                background-color: #f8f1f8;
            }
            .ca-app-ui-filter-kategorie a.active {
                color:            var(--ca-ui-accent-fg-hover);
                background-color: var(--ca-ui-accent-bg-hover);
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const kategorien = [
            {
                name: 'Glutenfrei',
                id:   'kat_glutenfrei'
            },
            {
                name: 'All in one',
                id:   'kat_allinone'
            },
            {
                name: 'Thermomix',
                id:   'kat_thermomix'
            },
            {
                name: 'Überbacken',
                id:   'kat_ueberbacken'
            },
            {
                name: 'Vegan',
                id:   'kat_vegan'
            },
            {
                name: 'Dörrgerät',
                id:   'kat_doerrgeraet'
            },
            {
                name: 'Putzmittel',
                id:   'kat_putzmittel'
            }
        ];
        let kategorieStr = '';

        for (const kategorie of kategorien) {
            kategorieStr += `<a href="#" kategorie="${kategorie.id}">${kategorie.name}</a>`;
        }

        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div class="ca-app-ui-filter-kategorie">
                ${kategorieStr}
            </div>
        `;
        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const buttons = this.shadowRoot.querySelectorAll('.ca-app-ui-filter-kategorie a');

        for (const button of buttons) {
            const eventListener = (event) => this.onButtonPress(event, button);

            button.addEventListener(
                'click',
                eventListener
            );

            this.eventListeners.push(eventListener);
        }
    }

    onButtonPress(event, button) {
        const buttons   = this.shadowRoot.querySelectorAll('.ca-app-ui-filter-kategorie a');
        const kategorie = button.getAttribute('kategorie');

        for (const button of buttons) {
            button.classList.remove('active');
        }

        if (kategorie !== this.kategorie) {
            this.shadowRoot.querySelector(`.ca-app-ui-filter-kategorie a[kategorie="${kategorie}"]`).classList.add('active');
            this.kategorie = kategorie;
        } else {
            this.kategorie = '';
        }

        this.parentNode.host.parentNode.host.caUiSidebarEventAggregator.publish(
            'CaAppUiFilterKategorie.reloadList:request',
            {
                kategorie: this.kategorie
            }
        );

        event.preventDefault();
    }
}

customElements.define('ca-app-ui-filter-kategorie', CaAppUiFilterKategorie);

export {CaAppUiFilterKategorie};

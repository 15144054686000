import {CaToolQuery}           from '../ca-tools/ca-tool-query.js';
import {CaToolEventAggregator} from '../ca-tools/ca-tool-event-aggregator';
import {CaAppEventAggregators} from '../ca-app/ca-app-event-aggregators';
import {CaAppLoginState}       from '../ca-app/ca-app-login-state';
import { CaAppConfig } from '../ca-app/ca-app-config.js';

class RezeptPage extends HTMLElement {

    constructor() {
        super();

        this.stageEventAggregator = new CaToolEventAggregator();
        this.subscriptions        = [];

        this.subscriptions.push(
            this.stageEventAggregator.subscribe(
                'CaStageController:stageActivated:request',
                (params) => this.answerCaStageControllerStageActivatedRequest(params)
            )
        );

        this.attachShadow({mode: 'open'});

        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                overflow:       auto;
                display:        flex;
                flex:           1;
                flex-direction: column;
                color:          var(--ca-ui-viewport-color--fg);
            }
            b {
                font-weight: 550;
            }
            #rezept {
                display:                    flex;
                flex-direction:             column;
                flex:                       1;
                overflow:                   auto;
                padding:                    0 16px;
                -webkit-overflow-scrolling: touch;
            }
            #rezept h2 {
                display:        inline-block;
                vertical-align: middle;
                font-size:      2.2em;
                font-weight:    600;
                font-stretch:   95%;
                line-height:    1.2em;
                color:          #b434b2;
                margin:         6px 0 0 0;
            }
            #rezept .kategorie {
                display:          inline-block;
                align-self:       center;
                vertical-align:   middle;
                font-size:        12px;
                font-weight:      600;
                color:            #fff;
                background-color: #b434b2;
                border-radius:    36px;
                padding:          4px 8px;
                margin:           10px 12px 2px 0;
            }
            #rezept h3 {
                font-size:    1.4em;
                font-weight:  500;
                line-height:  1.4em;
                font-stretch: 95%;
                margin:       0 0 8px 0;
            }
            #rezept ul {
                list-style: none;
                margin:     0;
                padding:    0;
            }
            #rezept ul li {
                border-bottom:  1px #bbb dashed;
                margin-top:     5px;
                padding-bottom: 6px;
            }
            #rezept ul li:last-child {
                border-bottom: none;
            }
            #rezept ul li.divider {
                margin-top:          -1px;
                font-weight:         600;
                border-bottom-style: solid;
                border-bottom-color: #444;
                background-color:    inherit;
                padding-top:         24px;
            }
            #rezept ul li.divider:first-child {
                padding-top: 0;
            }

            #rezept ol.circle-items {
                margin:        0;
                padding:       0;
                counter-reset: li;
            }
            #rezept ol.circle-items li {
                position:       relative;
                margin-left:    2.5em;
                list-style:     none;
                margin-top:     7px;
                padding-bottom: 8px;
            }
            #rezept ol.circle-items li::before {
                font-family:       verdana, arial, sans-serif;
                content:           counter(li);
                counter-increment: li;
                position:          absolute;
                top:               -2px;
                left:              -2.5em;
                box-sizing:        border-box;
                width:             23px;
                height:            23px;
                font-size:         12px;
                font-weight:       600;
                line-height:       23px;
                text-align:        right;
                padding-right:     5px;
                border-right:      1px #333 solid;
                color:             #333;
            }

            .row {
                display:        flex;
                flex-direction: row;
            }
            .col {
                flex:           1;
                flex-direction: column;
            }
            .image-gallery {
                display:          flex;
                flex-direction:   row;
                overflow-x:       auto;
                scroll-behavior:  smooth;
                scroll-snap-type: x mandatory;
                border-radius:    4px;

                scrollbar-width: none;
            }
            .image-gallery::-webkit-scrollbar {
                display: none;
            }
            .image-gallery .image-item {
                display:           flex;
                position:          relative;
                min-width:         100%;
                justify-content:   center;
                scroll-snap-align: start;
                background-color:  #eee;
            }
            .image-gallery .image-item img {
                float:         left;
                border-radius: 4px;
            }
            .image-nav-panel {
                position:    absolute;
                top:         0;
                width:       calc(50% - 20px);
                height:      100%;
                display:     flex;
                align-items: center;
                font-size:   3rem;
                text-shadow: 0px 0px 2px #fff;
                cursor:      pointer;
            }
            .image-nav-panel.left {
                left:         0;
                padding-left: 20px;
            }
            .image-nav-panel.right {
                right:           0;
                justify-content: flex-end;
                padding-right:   20px;
            }

            @media only screen and (max-width: 567px) {
                .row {
                    flex-direction: column;
                }
            }
            @media print {
                #rezept ul.zutaten li {
                    margin-top:     3px;
                    padding-bottom: 4px;
                }
                #rezept .col.zutaten {
                    max-width: 220px !important;
                }
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {

    }

    // ---------------------------------------------------------------------

    answerCaStageControllerStageActivatedRequest(params) {
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__suche'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__status'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.addItem:request',
            {
                sectionCenter: [
                    {
                        id:         '__status',
                        type:       'text',
                        label:      'Rezept',
                        fontWeight: 500
                    }
                ]
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'right',
                id:      '__create'
            }
        );

        const sectionRight = [];

        if (CaAppLoginState.isLoggedIn) {
            sectionRight.push({
                type:     'button',
                id:       '__delete',
                label:    'Löschen',
                icon:     'ca-icon-trash',
                callback: () => {
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:addStage:request',
                        {
                            id:           'loeschen',
                            stageElement: 'rezept-delete-page'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:gotoStage:request',
                        {
                            id:     'loeschen',
                            params: {
                                id: this.rezept.id
                            }
                        }
                    );
                }
            });
            sectionRight.push({
                type:     'button',
                id:       '__edit',
                label:    'Bearbeiten',
                icon:     'ca-icon-edit',
                callback: () => {
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:addStage:request',
                        {
                            id:           'bearbeiten',
                            stageElement: 'rezept-edit-page'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:gotoStage:request',
                        {
                            id:     'bearbeiten',
                            params: {
                                id: this.rezept.id
                            }
                        }
                    );
                }
            });
        } else {
            sectionRight.push({
                type:     'button',
                id:       '__login',
                label:    'Anmelden',
                icon:     'ca-icon-modul_AV',
                callback: () => {
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:addStage:request',
                        {
                            id:           'anmeldung',
                            stageElement: 'anmeldung-page'
                        }
                    );
                    CaAppEventAggregators.eventAggregatorStage.publish(
                        'CaStageController:gotoStage:request',
                        {
                            id:     'anmeldung',
                            params: {
                                originStage: 'rezept'
                            }
                        }
                    );
                }
            });
        }

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.config.set:response',
            {
                sectionLeft: [
                    {
                        type:     'button',
                        id:       '__back',
                        label:    'Zurück',
                        icon:     'ca-icon-chevron-thin-left',
                        callback: () => {
                            CaAppEventAggregators.eventAggregatorToolbar.publish(
                                'toolbar.update.deleteItem:request',
                                {
                                    section: 'left',
                                    id:      '__back'
                                }
                            );
                            CaAppEventAggregators.eventAggregatorStage.publish(
                                'CaStageController:gotoStage:request',
                                {
                                    id:        'rezepte',
                                    backwards: true
                                }
                            );
                        }
                    }
                ],
                sectionRight: sectionRight
            }
        );

        if (params.originStage !== 'anmeldung') {
            this.loadRezept(params.id);
        }
    }

    async loadRezept(id) {
        const wsRezept = await CaToolQuery.getRezept(id);

        if (wsRezept.error === '0') {
            this.rezept = wsRezept.rezept;

            this.rezept.preparedSchritte = this.prepareSchritte(this.rezept.schritte);
            this.rezept.preparedZutaten  = this.prepareZutaten(this.rezept.zutaten);
            this.rezept.beschreibung     = this.rezept.beschreibung.replace(/\n/gi, '<br>');

            if (this.rezept.kat_glutenfrei  === '1' ||
                this.rezept.kat_allinone    === '1' ||
                this.rezept.kat_thermomix   === '1' ||
                this.rezept.kat_vegan       === '1' ||
                this.rezept.kat_ueberbacken === '1' ||
                this.rezept.kat_doerrgeraet === '1') {

                this.rezept.hasKategorie = true;
            } else {
                this.rezept.hasKategorie = false;
            }

            this.renderRezept();
        } else {
            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:showFatalError:request',
                wsRezept
            );
        }
    }

    renderRezept() {
        let katStr = '';

        if (this.rezept.kat_glutenfrei === '1') {
            katStr += `<span class="kategorie">
                Glutenfrei
            </span>`;
        }
        if (this.rezept.kat_allinone === '1') {
            katStr += `<span class="kategorie">
                All in one
            </span>`;
        }
        if (this.rezept.kat_thermomix === '1') {
            katStr += `<span class="kategorie">
                Thermomix
            </span>`;
        }
        if (this.rezept.kat_vegan === '1') {
            katStr += `<span class="kategorie">
                Vegan
            </span>`;
        }
        if (this.rezept.kat_doerrgeraet === '1') {
            katStr += `<span class="kategorie">
                Dörrgerät
            </span>`;
        }

        let beschrStr = '';

        if (this.rezept.beschreibung) {
            beschrStr += `<div
                style = "padding-bottom: 30px; line-height: 136%;"
            >
                ${this.rezept.beschreibung}
            </div>`;
        }

        let quelleStr = '';

        if (this.rezept.quelle) {
            quelleStr += `<div style="margin-bottom: 16px;">
                <p style="margin-bottom: 4px;"><b style="font-weight: 600;">Quelle:</b></p>
                <p style="margin-top: 0; font-size: 89%;">${this.rezept.quelle}</p>
            </div>
            `;
        }

        let schritteStr = '';

        if (this.rezept.schritte) {
            schritteStr += `<div class="col" style="flex: 1;">
                <h3>Schritte:</h3>
                <div>
                    ${this.rezept.preparedSchritte}
                </div>
            </div>
            `;
        }

        let zutatenStr = '';

        if (this.rezept.zutaten) {
            zutatenStr += `<div class="col zutaten" style="min-width:150px; max-width:300px; flex: 0 0 auto; margin-right: 32px;">
                <h3>Zutaten:</h3>
                <div
                    style = "margin-bottom: 16px;"
                >
                    ${this.rezept.preparedZutaten}
                </div>
            </div>
            `;
        }

        let bilderStr = '';

        if (this.rezept.bilder !== '') {
            const images = this.rezept.bilder.split(';;');
            let imageIdx = 0;

            bilderStr += `<div style="margin-top: 17px;">
                <p style="margin-bottom: 4px;">
                    <b style="font-weight: 600;">Bilder:</b>
                </p>
            </div>`;
            bilderStr += '<div class="image-gallery">';

            for (const imageDef of images) {
                if (imageDef !== '') {
                    const [imagePath, imagePublicId] = imageDef.split('||');
                    let nextImageIdx = imageIdx + 1;
                    let prevImageIdx = imageIdx - 1;

                    if (imageIdx === images.length - 2) {
                        nextImageIdx = 0;
                    }

                    if (prevImageIdx < 0) {
                        prevImageIdx = images.length - 2;
                    }

                    bilderStr += `<div class="image-item" id="section-${imageIdx}">
                        <picture>
                            <source media="(max-width: 599px)" srcset="https://res.cloudinary.com/${CaAppConfig.cloudinary.cloudName}/image/upload/c_fit,h_200/${imagePath}" />
                            <source media="(min-width: 600px)" srcset="https://res.cloudinary.com/${CaAppConfig.cloudinary.cloudName}/image/upload/c_fit,h_600/${imagePath}" />
                            <img
                                src     = "https://res.cloudinary.com/${CaAppConfig.cloudinary.cloudName}/image/upload/c_fit,h_600/${imagePath}"
                                loading = "lazy"
                                alt     = "Bild zu Rezept"
                            ></img>
                        </picture>
                        <a
                            class             = "image-nav-panel left"
                            data-to-image-idx = "${prevImageIdx}"
                        >&lt;</a>
                        <a
                            class             = "image-nav-panel right"
                            data-to-image-idx = "${nextImageIdx}"
                        >&gt;</a>
                    </div>`;

                    imageIdx++;
                }
            }

            bilderStr += '</div>';
        }

        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div id="rezept">
                <div class="content inset">
                    <div style="display: flex; flex-wrap: wrap; align-content: center; justify-content: flex-start; margin-top: 12px; margin-bottom: 16px;">
                        <h2 style="${this.rezept.hasKategorie ? 'margin-right: 12px;' : ''}">${this.rezept.name}</h2>
                        ${katStr}
                    </div>
                    ${beschrStr}
                    <div>
                        <div class="row wrap-on-phone" style="margin-bottom: 16px;">
                            ${zutatenStr}
                            ${schritteStr}
                        </div>
                    </div>
                    ${quelleStr}
                    ${bilderStr}
                </div>
            </div>
        `;

        while (
            this.shadowRoot.lastChild &&
            this.shadowRoot.lastChild.nodeName !== 'STYLE'
        ) {
            this.shadowRoot.removeChild(this.shadowRoot.lastChild);
        }

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const imageLinks = this.shadowRoot.querySelectorAll('.image-item a');

        imageLinks.forEach(
            (imageLink) => {
                imageLink.addEventListener(
                    'click',
                    (event) => {
                        const scrollToElem = this.shadowRoot.querySelector(`#section-${imageLink.getAttribute('data-to-image-idx')}`);

                        if (scrollToElem) {
                            scrollToElem.scrollIntoView();
                        }
                    }
                );
            }
        );
    }

    prepareSchritte(schritte) {
        let preparedSchritte     = '';
        let schritteListe = schritte.split("\n");

        if (schritteListe.length > 0) {
            preparedSchritte += '<ol class="circle-items">';

            for (let schritt of schritteListe) {
                let cleanedSchritt = schritt.replace(/\s/gi, '');

                if (cleanedSchritt === '') {
                    if (preparedSchritte !== '<ol class="circle-items">') {
                        preparedSchritte += '</li>';
                    }
                    preparedSchritte += '<li>';
                } else {
                    if (preparedSchritte === '<ol class="circle-items">') {
                        preparedSchritte += '<li>';
                    }
                    preparedSchritte += schritt + '<br>';
                }
            }

            preparedSchritte += '</li></ol>';
        }


        return preparedSchritte;
    }

    prepareZutaten(zutaten) {
        let preparedZutaten     = '',
            zutatenListe = zutaten.split("\n");

        if (zutatenListe.length > 0) {
            preparedZutaten += '<ul class="zutaten">';

            let idx = 0;

            for (let zutat of zutatenListe) {
                let cleanedZutat = zutat.replace(/\s/gi, '');

                if (cleanedZutat !== '') {
                    let noBottomBorder = false;

                    let addIdx = 1;

                    while (idx + addIdx < zutatenListe.length) {
                        let nextZutat = zutatenListe[idx + addIdx].replace(/\s/gi, '');

                        if (nextZutat.substring(0, 1) === '#') {
                            noBottomBorder = true;
                        }

                        if (nextZutat !== '') {
                            break;
                        }

                        addIdx++;
                    }

                    // Überschrift
                    if (cleanedZutat.substring(0, 1) === '#') {
                        preparedZutaten += '<li class="divider">' + zutat.replace(/#[\s]*/gi, '') + '</li>';
                    } else {
                        preparedZutaten += '<li' + (noBottomBorder ? ' style="border-bottom: none;"' : '') + '>' + zutat + '</li>';
                    }
                }

                idx++;
            }

            preparedZutaten += '</ul>';
        }

        return preparedZutaten;
    }
}

customElements.define('rezept-page', RezeptPage);

export {RezeptPage};

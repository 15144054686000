import {CaToolRequest} from './ca-tool-request';

const CaToolQuery = {

    filterByArtId:      '',
    filterByKategorie:  '',
    filterBySearchTerm: '',

    async createRezept({
        rezept = {}
    }) {
        const preparedRezept = {};

        for (const key in rezept) {
            preparedRezept[key.substring(2)] = rezept[key];
        }

        const ws = await CaToolRequest.WS_Start_Service({
                'method': 'POST',
                'object': 'rezept',
                'params': preparedRezept
            });

        return ws;
    },

    async deleteImage({
        publicId = ''
    }) {
        const ws = await CaToolRequest.WS_Start_Service({
                'method': 'POST',
                'object': 'image_delete',
                'params': {
                    publicId: publicId
                }
            });

        return ws;
    },

    async deleteRezept({
        rezeptId = ''
    }) {
        const ws = await CaToolRequest.WS_Start_Service({
                'method': 'POST',
                'object': 'rezept_delete',
                'params': {
                    id: rezeptId
                }
            });

        return ws;
    },

    async editRezept({
        rezept   = {},
        rezeptId = ''
    }) {
        const preparedRezept = {
            id: rezeptId
        };

        for (const key in rezept) {
            preparedRezept[key.substring(2)] = rezept[key];
        }
        const ws = await CaToolRequest.WS_Start_Service({
                'method': 'POST',
                'object': 'rezept_edit',
                'params': preparedRezept
            });

        return ws;
    },

    async getArten() {
        const ws  = await CaToolRequest.WS_Start_Service({
            method: 'GET',
            object: 'arten'
        });

        return ws;
    },

    async getRezept(rezeptId) {
        const result = {
            error:     '0',
            errortext: '',
            rezept:    {}
        };
        const ws = await CaToolRequest.WS_Start_Service({
            'method': 'GET',
            'object': 'rezept',
            'id':     rezeptId
        });

        if (ws.error === '0') {
            result.rezept = ws.response;
        } else {
            result.error     = ws.error;
            result.errortext = ws.errortext;
        }

        return result;
    },

    async getRezepte() {
        const result = {
            error:     '0',
            errortext: '',
            rezepte:   []
        };
        const ws = await CaToolRequest.WS_Start_Service({
            'method':     'GET',
            'object':     'rezepte',
            'id':         this.filterByArtId,
            'kat_id':     this.filterByKategorie,
            'searchterm': window.btoa(unescape(encodeURIComponent(this.filterBySearchTerm)))
        });

        if (ws.error === '0') {
            result.rezepte = ws.response;
        } else {
            result.error     = ws.error;
            result.errortext = ws.errortext;
        }

        return result;
    },

    setFilterBySearchTerm(searchTerm) {
        this.filterBySearchTerm = searchTerm;
    },

    setFilterByArtId(artId) {
        this.filterByArtId = artId;
    },

    setFilterByKategorie(kategorie) {
        this.filterByKategorie = kategorie;
    },

    async doLogin({
        username = '',
        password = ''
    }) {
        const ws = await CaToolRequest.WS_Start_Service({
            'method': 'POST',
            'object': 'login',
            'params': {
                'username': username,
                'password': password
            }
        });

        return ws;
    },

    async checkLoginHash({
        hash = ''
    }) {
        const ws = await CaToolRequest.WS_Start_Service({
            'method': 'POST',
            'object': 'login_hash',
            'params': {
                'hash': hash
            }
        });

        return ws;
    }
}

export {CaToolQuery};

/**
 * Stellt systemweit die Konfiguration zur Verfügung.
 */
const CaAppConfig = {

    arten:      [],
    baseUrl:    '/',
    cloudinary: {
        cloudName:    'dqcht8zf0',
        uploadPreset: 'x5gjxs8s',
    },
    connection: {
        useRemoteServer:       false,
        remoteServerAddress:   '',
        remoteServerUsesProxy: false
    },
    dateFormat:   {},
    defaultColor: '#b13a12',
    useLang:      'de',

    async loadConfiguration() {
        let error          = '0';
        let errortext      = '';
        let configResponse = await fetch('./resources/config/config.txt');

        if (configResponse.ok) {
            let config = await configResponse.text();

            this.parseConfigurationFile(config);
        } else {
            error     = '-98';
            errortext = 'Konnte Konfigurationsdatei nicht laden';
        }

        return {
            error:     error,
            errortext: errortext
        }
    },

    getArten() {
        return this.arten;
    },

    setArten(arten) {
        this.arten = arten;
    },

    getBaseUrl() {
        return this.baseUrl;
    },

    setBaseUrl() {
        this.baseUrl = window.location.pathname;

        if (this.baseUrl !== '/') {
            const baseUrlSegments = this.baseUrl.split('/');
            const lastSegment     = baseUrlSegments.pop();

            if (lastSegment === this.getHash()) {
                baseUrlSegments.pop();
            }

            this.baseUrl = baseUrlSegments.join('/') + '/';
        }
    },

    parseConfigurationFile(content) {
        let key;
        let value;
        let lines = content.split("\n");

        for (const line of lines) {
            if (line.indexOf(':') > -1) {
                let lineElems = line.split(':');
                key           = lineElems.shift().trim();
                value         = lineElems.join(':').trim();

                if (key === 'useRemoteServer') {
                    this.connection.useRemoteServer = value === 'true' ? true : false;
                } else if (key === 'remoteServerAddress') {
                    this.connection.remoteServerAddress = value;
                } else if (key === 'remoteServerUsesProxy') {
                    this.connection.remoteServerUsesProxy = value === 'true' ? true : false;
                }
            }
        }
    }
}

export {CaAppConfig};

import {CaToolEventAggregator} from '../ca-tools/ca-tool-event-aggregator';
import {CaToolQuery}           from '../ca-tools/ca-tool-query';
import {CaAppEventAggregators} from '../ca-app/ca-app-event-aggregators';
import {CaAppLoginState}       from '../ca-app/ca-app-login-state';

class AnmeldungPage extends HTMLElement {

    constructor() {
        super();

        this.caFormLayoutEventAggregator     = new CaToolEventAggregator();
        this.caUiNotificationEventAggregator = new CaToolEventAggregator();
        this.originStage                     = '';
        this.stageEventAggregator            = new CaToolEventAggregator();
        this.subscriptions                   = [];

        this.subscriptions.push(
            this.stageEventAggregator.subscribe(
                'CaStageController:stageActivated:request',
                (params) => this.answerCaStageControllerStageActivatedRequest(params)
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:attached:request',
                () => this.answerCaFormLayoutAttachedRequest()
            )
        );
        this.subscriptions.push(
            this.caFormLayoutEventAggregator.subscribe(
                'CaFormLayout:submit:request',
                (params) => this.answerCaFormLayoutSubmitRequest(params)
            )
        );
        this.subscriptions.push(
            this.caUiNotificationEventAggregator.subscribe(
                'CaUiNotification.config.set:request',
                (params) => this.answerCaUiNotificationConfigSetRequest(params)
            )
        );

        this.attachShadow({mode: 'open'});

        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <ca-ui-notification></ca-ui-notification>
            <ca-form-layout></ca-form-layout>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));
    }

    // ------------------------------------------------------------------------

    answerCaStageControllerStageActivatedRequest(params) {
        this.originStage = params.originStage;

        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__suche'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'center',
                id:      '__status'
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.addItem:request',
            {
                sectionCenter: [
                    {
                        id:         '__status',
                        type:       'text',
                        label:      'Anmeldung',
                        fontWeight: 500
                    }
                ]
            }
        );
        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.update.deleteItem:request',
            {
                section: 'right',
                id:      '__login'
            }
        );


        CaAppEventAggregators.eventAggregatorToolbar.publish(
            'toolbar.config.set:response',
            {
                sectionLeft: [
                    {
                        type:     'button',
                        id:       '__back',
                        label:    'Zurück',
                        icon:     'ca-icon-chevron-thin-left',
                        callback: () => {
                            CaAppEventAggregators.eventAggregatorToolbar.publish(
                                'toolbar.update.deleteItem:request',
                                {
                                    section: 'left',
                                    id:      '__back'
                                }
                            );
                            CaAppEventAggregators.eventAggregatorStage.publish(
                                'CaStageController:gotoStage:request',
                                {
                                    id:        this.originStage,
                                    backwards: true,
                                    params:    {
                                        originStage: 'anmeldung'
                                    }
                                }
                            );
                        }
                    }
                ]
            }
        );
    }

    answerCaFormLayoutAttachedRequest() {
        let username = '';
        let password = '';

        this.caFormLayoutEventAggregator.publish(
            'CaFormLayout:attached:response',
            {
                config: {
                    align:        'center',
                    borderRadius: true,
                    minWidth:     180,
                    maxWidth:     480,
                    marginTop:    50,
                    padding:      'large',
                    useShadow:    true,
                    style:        `
                        :host {
                            --ca-ui-font-size--form-input: 18px;

                            --ca-ui-form-bg: var(--ca-ui-accent-bg);
                            --ca-ui-form-fg: var(--ca-ui-accent-fg);

                            --ca-ui-form-label--fg: var(--ca-ui-accent-fg);

                            --ca-ui-form-button-submit--bg:                 var(--ca-ui-accent-fg);
                            --ca-ui-form-button-submit--bg-hover:           var(--ca-ui-accent-fg-hover);
                            --ca-ui-form-button-submit--fg:                 var(--ca-ui-accent-bg);
                            --ca-ui-form-button-submit--fg-hover:           var(--ca-ui-accent-bg-hover);
                            --ca-ui-form-button-submit--border-color:       var(--ca-ui-accent-fg);
                            --ca-ui-form-button-submit--border-color-hover: var(--ca-ui-accent-fg-hover);

                            --ca-ui-form-input--bg:           #f8f1f8;
                            --ca-ui-form-input--border-color: #922a91;
                            --ca-ui-form-input--fg:           #5f1b5e;

                            --ca-ui-notification-color--bg: #fff;
                        }
                    `,
                    layoutDefinition: {
                        rows: [
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-input',
                                        config: {
                                            align:            'stretch',
                                            label:            'Benutzername',
                                            fieldId:          '__username',
                                            validateOnSubmit: {
                                                notEmpty: true,
                                            },
                                            validateOnInput: {
                                                noSurroundingWhitespace: true
                                            },
                                            focus: true
                                        }
                                    }
                                ]
                            },
                            {
                                cols: [
                                    {
                                        elementType: 'ca-form-field-input',
                                        config: {
                                            align:            'stretch',
                                            label:            'Passwort',
                                            fieldId:          '__password',
                                            type:             'password',
                                            validateOnSubmit: {
                                                notEmpty: true
                                            },
                                            validateOnInput: {
                                                noSurroundingWhitespace: true
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                config: {
                                    marginTop: 20
                                },
                                cols: [
                                    {
                                        elementType: 'ca-form-button-submit',
                                        config: {
                                            align: 'stretch',
                                            title: 'Anmelden'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                },
                record: {
                    __username: username,
                    __password: password
                }
            }
        );
    }

    /**
     * Anmeldung am Server
     */
    async answerCaFormLayoutSubmitRequest({
        record = {}
    }) {
        this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusLoading:response');

        const ws = await CaToolQuery.doLogin({
            username: record['__username'],
            password: record['__password']
        });

        if (
            ws.error === '0' &&
            ws.response &&
            ws.response.display_name !== ''
        ) {
            CaAppLoginState.isLoggedIn = true;
            CaAppLoginState.setLocalUserHash(ws.response.hash);

            CaAppEventAggregators.eventAggregatorToolbar.publish(
                'toolbar.update.deleteItem:request',
                {
                    section: 'left',
                    id:      '__back'
                }
            );
            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:gotoStage:request',
                {
                    id:        this.originStage,
                    backwards: true,
                    params:    {
                        originStage: 'anmeldung'
                    }
                }
            );

            setTimeout(
                () => {
                    this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');
                },
                50
            );
        } else {
            this.caFormLayoutEventAggregator.publish('CaFormLayout:setStatusNotLoading:response');
            const errorDef = {
                error:     ws.error,
                errortext: ws.errortext || 'Zugangsdaten sind falsch',
                config:    {
                    allowClose: true
                }
            };

            CaAppEventAggregators.eventAggregatorStage.publish(
                'CaStageController:showFatalError:request',
                errorDef
            );
        }
    }

    answerCaUiNotificationConfigSetRequest() {}
}

customElements.define('anmeldung-page', AnmeldungPage);

export {AnmeldungPage};

import {CaAppConfig} from '../ca-app/ca-app-config';

class CaAppUiFilterArt extends HTMLElement {

    constructor() {
        super();

        this.artId          = '';
        this.eventListeners = [];
        this.subscriptions  = [];

        this.attachShadow({mode: 'open'});

        this.setStyle();
        this.setTemplate();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                display: flex;
                margin:  8px;
            }
            .ca-app-ui-filter-art {
                display:        flex;
                flex:           1;
                flex-direction: column;
                flex-wrap:      nowrap;
                border-radius:  3px;
                border:         1px #b434b2 solid;
            }
            .ca-app-ui-filter-art a {
                flex:            1;
                color:           #b434b2;
                text-decoration: none;
                border-bottom:   1px #f8f1f8 solid;
                padding:         8px;
            }
            .ca-app-ui-filter-art a:first-child {
                border-top-left-radius:  3px;
                border-top-right-radius: 3px;
            }
            .ca-app-ui-filter-art a:last-child {
                border-bottom:              none;
                border-bottom-left-radius:  3px;
                border-bottom-right-radius: 3px;
            }
            .ca-app-ui-filter-art a:hover {
                background-color: #f8f1f8;
            }
            .ca-app-ui-filter-art a.active {
                color:            var(--ca-ui-accent-fg-hover);
                background-color: var(--ca-ui-accent-bg-hover);
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const arten = CaAppConfig.getArten();
        let artenStr = '';

        for (const art of arten) {
            artenStr += `<a href="#" art-id="${art.id}">${art.name}</a>`;
        }

        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div class="ca-app-ui-filter-art">
                ${artenStr}
            </div>
        `;
        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const buttons = this.shadowRoot.querySelectorAll('.ca-app-ui-filter-art a');

        for (const button of buttons) {
            const eventListener = (event) => this.onButtonPress(event, button);

            button.addEventListener(
                'click',
                eventListener
            );

            this.eventListeners.push(eventListener);
        }
    }

    onButtonPress(event, button) {
        const buttons = this.shadowRoot.querySelectorAll('.ca-app-ui-filter-art a');
        const artId   = button.getAttribute('art-id');

        for (const button of buttons) {
            button.classList.remove('active');
        }

        if (artId !== this.artId) {
            this.shadowRoot.querySelector(`.ca-app-ui-filter-art a[art-id="${artId}"]`).classList.add('active');
            this.artId = artId;
        } else {
            this.artId = '';
        }

        this.parentNode.host.parentNode.host.caUiSidebarEventAggregator.publish(
            'CaAppUiFilterArt.reloadList:request',
            {
                artId: this.artId
            }
        );

        event.preventDefault();
    }
}

customElements.define('ca-app-ui-filter-art', CaAppUiFilterArt);

export {CaAppUiFilterArt};

import {CaToolEventAggregator} from '../../ca-tools/ca-tool-event-aggregator';

/**
 * Stellt eine Darstellungsmöglichkeit für Benachrichtigungen zur Verfügung.
 */
class CaUiFatalError extends HTMLElement {

    constructor() {
        super();

        this.caUiNotificationEventAggregator = new CaToolEventAggregator();
        this.config                          = {};
        this.fatalErrorDef                   = {
            error:     '',
            errortext: '',
            config:    {
                allowClose: false
            }
        };
        this.subscriptions = [];

        this.subscriptions.push(
            this.caUiNotificationEventAggregator.subscribe(
                'CaUiNotification.config.set:request',
                () => this.answerCaUiNotificationConfigSetRequest()
            )
        );
        this.subscriptions.push(
            this.caUiNotificationEventAggregator.subscribe(
                'CaUiNotification.action.close:request',
                () => this.answerCaUiNotificationActionCloseRequest()
            )
        );

        this.attachShadow({mode: 'open'});
        this.setStyle();
    }

    setStyle() {
        let style = document.createElement('style');

        style.textContent = `
            :host {
                position:         absolute;
                left:             0;
                top:              0;
                right:            0;
                bottom:           0;
                opacity:          0;
                transition:       opacity 0.5s;
                background-color: rgba(0, 0, 0, 0.9);
                padding:          0 20%;
            }
            .ca-ui-fatal-error {
            }

            @media only screen and (max-width: 567px) {
                :host {
                    padding: 0 16px;
                }
            }
        `;

        this.shadowRoot.appendChild(style);
    }

    setTemplate() {
        const tmpl = document.createElement('template');
        tmpl.innerHTML = `
            <div class="ca-ui-fatal-error" tabindex="-1">
                <div
                    class       = "ca-focus-trapstart"
                    tabindex    = "-1"
                    aria-hidden = "true"
                ></div>
                <ca-ui-notification></ca-ui-notification>
                <div
                    class       = "ca-focus-trapend"
                    tabindex    = "-1"
                    aria-hidden = "true"
                ></div>
                <div
                    tabindex    = "2"
                    class       = "ca-focus-handle-trap-end"
                    aria-hidden = "true"
                ></div>
            </div>
        `;

        this.shadowRoot.appendChild(tmpl.content.cloneNode(true));

        const caFocusStartElem = this.shadowRoot.querySelector('.ca-ui-fatal-error');
        this.eventListenerCaFocusStartElem = () => this.focusHandleStart();
        caFocusStartElem.addEventListener(
            'focus',
            this.eventListenerCaFocusStartElem
        );
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-handle-trap-end');
        this.eventListenerCaFocusEndElem = () => this.focusHandleEnd();
        caFocusEndElem.addEventListener(
            'focus',
            this.eventListenerCaFocusEndElem
        );
    }

    // ---------------------------------------------------------------------

    connectedCallback() {
        setTimeout(
            () => {
                this.shadowRoot.host.style.opacity = 1;
            },
            50
        );

        this.subscriptions.push(
            this.parentNode.host.caUiFatalErrorEventAggregator.subscribe(
                'CaUiFatalError.config.set:response',
                (params) => this.receiveCaUiFatalErrorConfigSetResponse(params)
            )
        );
        this.parentNode.host.caUiFatalErrorEventAggregator.publish('CaUiFatalError.config.set:request');
    }

    disconnectedCallback() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }

        const caFocusStartElem = this.shadowRoot.querySelector('.ca-ui-fatal-error');
        caFocusStartElem.removeEventListener(
            'focus',
            this.eventListenerCaFocusStartElem
        );
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-handle-trap-end');
        caFocusEndElem.removeEventListener(
            'focus',
            this.eventListenerCaFocusEndElem
        );
    }

    // ---------------------------------------------------------------------

    focusHandleStart() {
        const caFocusEndElem = this.shadowRoot.querySelector('.ca-focus-trapend');

        if (caFocusEndElem) {
            caFocusEndElem.focus();
        }
    }

    focusHandleEnd() {
        const caFocusStartElem = this.shadowRoot.querySelector('.ca-ui-fatal-error');

        if (caFocusStartElem) {
            caFocusStartElem.focus();
        }
    }

    answerCaUiNotificationActionCloseRequest() {
        this.shadowRoot.host.style.opacity = 0;

        setTimeout(
            () => {
                this.parentNode.host.caUiFatalErrorEventAggregator.publish(
                    'CaUiFatalError.close:request'
                );
            },
            500
        );
    }

    answerCaUiNotificationConfigSetRequest() {
        this.caUiNotificationEventAggregator.publish(
            'CaUiNotification.config.set:response',
            {
                config: {
                    marginTop: 40,
                    class:     'fatal-error',
                    style: `
                        .ca-notifications.fatal-error .ca-notification {
                            color:        #fff;
                            border-width: 2px;
                        }
                    `
                },
                notifications: [
                    {
                        type:   'error',
                        title:  'Fehler ' + this.fatalErrorDef.error,
                        text:   this.fatalErrorDef.errortext,
                        config: this.fatalErrorDef.config
                    }
                ]
            }
        );
    }

    receiveCaUiFatalErrorConfigSetResponse({
        ws = {}
    }) {
        this.fatalErrorDef.error     = ws.error;
        this.fatalErrorDef.errortext = ws.errortext;
        this.fatalErrorDef.config    = ws.config;

        this.setTemplate();
    }
}

customElements.define('ca-ui-fatal-error', CaUiFatalError);

export {CaUiFatalError};
